import { Input, Button, message, Select, Form, Row, Col, Space, Avatar, Modal, Spin, Typography } from 'antd';
import { fetchPosts } from '../../api/community';
import { useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { conversionUtcDate } from '../../utils/comm';
import { createPMInsights, updatePMInsights } from '../../api/privatemarket';

const InsightsModalView = ({ open, oldData, onClose, onUpdate, isVideo }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [form] = Form.useForm();
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        form.setFieldValue('search', null);
        if (oldData) {
            form.setFieldValue('postId', oldData.post.id)
            form.setFieldValue('title', oldData.post.title)
            form.setFieldValue('weight', oldData.weight);
        } else {
            resetFields();
        }
        getPosts('');
    }, [oldData]);

    const resetFields = () => {
        form.setFieldValue('search', null);
        form.setFieldValue('postId', '')
        form.setFieldValue('title', '')
        form.setFieldValue('weight', '');
    }

    const onSubmit = () => {
        let params = {
            id: oldData?.id,
            postId: form.getFieldValue('postId'),
            weight: form.getFieldValue('weight') ? form.getFieldValue('weight') : 0,
        };
        if (!params.postId) {
            message.warning('Please choose a post');
            return;
        }
        setLoading(true);
        if (params.id) {
            updatePMInsights(params).then(res => {
                if (res) {
                    resetFields();
                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createPMInsights(params).then(res => {
                if (res) {
                    resetFields();
                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onSelectChange = (value) => {
        form.setFieldValue('postId', value);
        let post = posts.find(item => item.value === value);
        if (post) {
            form.setFieldValue('title', post.label);
        }
    }

    const handleSearch = (value) => {
        getPosts(value);
    }

    const getPosts = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
                'status': 1,
                'categoryId': 2,
                'isVideo': false
            }
            setSearchLoading(true);
            fetchPosts(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => {
                        let label = item.title;
                        return {
                            avatar: (item.user.picture ? item.user.picture : item.user.avatar),
                            createdAt: conversionUtcDate(item.createdAt, null, true),
                            label: label,
                            value: item.id,
                        }
                    });
                    setPosts(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const drawTitle = () => {
        return oldData ? 'Edit Insights-Cover' : 'Add Insighs-Cover';
    }

    return (
        <div>
            <Modal
                title={drawTitle()}
                width={480}
                onCancel={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='search' label='Search'>
                                <Select
                                    style={{ width: '100%' }}
                                    size='large'
                                    placeholder='you can input keywords to search...'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={posts}
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                {
                                                    option.data.avatar ?
                                                        <Avatar size={32} src={option.data.avatar} />
                                                        :
                                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                }
                                                <div style={{ flex: 1 }}>
                                                    <b style={{ wordBreak: 'break-word' }}>{option.data.label ? option.data.label : 'anonymous'}</b>
                                                    <div>
                                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                            {option.data.createdAt}
                                                        </Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    onChange={onSelectChange}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ height: 36, width: 200 }} />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='postId' label={'Article ID'}>
                                <Input placeholder="please input article id" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='title' label={'Article Title'}>
                                <Input.TextArea autoSize disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='weight' label='Weight'>
                                <Input placeholder="recommend weight" type='number' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default InsightsModalView;