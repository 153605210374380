import { useEffect, useState } from 'react'
import { Button, Table, Pagination, Flex, Space, Select, Popconfirm,Tag, Statistic } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { conversionUtcDate } from '../../utils/comm';
import { PlusOutlined } from '@ant-design/icons';
import { MainLayoutUrl } from '../../router/router_path';
import { useNavigate } from 'react-router-dom';
import { deletePMProduct, fetchAssetClass, fetchPMProducts } from '../../api/privatemarket';

const PMProductsScreen = () => {
    const navigate = useNavigate();
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [assets, setAssets] = useState([])
    const [searchParams, setSearchParams] = useState({
        assetClassId: '',
    });

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, _) => {
                return <div style={{width: 200}}><b>{text}</b></div>
            },
        },
        {
            title: 'Asset Class',
            dataIndex: 'assetClass',
            key: 'assetClass',
            render: (_, record) => {
                return <Tag>{record.assetClass?.name}</Tag>
            }
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (text, _) => {
                return <span style={{whiteSpace: 'nowrap'}}>{text}</span>
            }
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, _) => {
                return (
                    <div style={{ width: 160, color: '#afafaf' }}>
                        <div className="community-content">
                            {text}
                        </div>
                    </div>
                )
            },
            width: 200
        },
        {
            title: 'Info1',
            dataIndex: 'info1',
            key: 'info1',
            render: (_, record) => {
                return <Statistic title={<div style={{whiteSpace: 'nowrap'}}>{record.info1}</div>} value={record.infoNumber1} valueStyle={{ fontSize: 16, whiteSpace: 'nowrap', color: '#87d068' }} />
            }
        },
        {
            title: 'Info2',
            dataIndex: 'info2',
            key: 'info2',
            render: (_, record) => {
                return <Statistic title={<div style={{whiteSpace: 'nowrap'}}>{record.info2}</div>} value={record.infoNumber2} valueStyle={{ fontSize: 16, whiteSpace: 'nowrap', color: '#87d068' }} />
            }
        },
        {
            title: 'Investment Highlights',
            dataIndex: 'investmentHighlights',
            key: 'investmentHighlights',
            render: (text, _) => {
                return (
                    <div style={{ width: 160, color: '#afafaf' }}>
                        <div className="community-content">
                            {text}
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Subscription Status',
            dataIndex: 'subscriptionStatus',
            key: 'subscriptionStatus',
            render: (text, _) => {
                return <span style={{fontSize: 12}}>{text}</span>
            }
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                if (!text) return '-';
                return <span style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(text, null, true)}</span>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this product?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            },
            fixed: 'right'
        }
    ];

    useEffect(() => {
        getProducts(pageNum, pageSize, searchParams);
        fetchAssetClass({'page.disable': true}).then(res => {
            if (res.list) {
                setAssets(res.list.map(item => {
                    return {
                        key: item.id,
                        value: item.id,
                        label: item.name
                    }
                }))
            }
        })
    }, [])

    const getProducts = useDebouncedCallback(
        (pNum, pSize, search) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                ...search
            }
            setLoading(true);
            fetchPMProducts(params).then(res => {
                if (res.list) {
                    setTabData(res.list);
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        },
        300
    )

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getProducts(page, size, searchParams);
    }

    const editHandle = (record) => {
        navigate(MainLayoutUrl.privatemarket.privateproducts.item.path.replace(':id', record.id), { state: { detail: JSON.stringify(record) } })
    }

    const createNewProduct = () => {
        navigate(MainLayoutUrl.privatemarket.privateproducts.item.path.replace(':id', '0'));
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deletePMProduct(record.id).then(res => {
            if (res) {
                getProducts(pageNum, pageSize, searchParams);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            getProducts(1, pageSize, newParams);
        },
        300
    )

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={createNewProduct}>Create New Product</Button>
                    <Space>
                        <Select
                            style={{ width: 240 }}
                            options={assets}
                            placeholder='Asset Class'
                            onChange={value => onSearchChange('assetClassId', value)}
                            allowClear
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table scroll={{ x: true }} loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default PMProductsScreen;