import { useParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import '../../style/discover.css';
import { getCourseItems } from "../../api/course";
import BasicCourseView from "../../components/course/basic_course_view";
import { MainLayoutUrl } from "../../router/router_path";
import CourseQuestionView from "../../components/course/course_question_view";
import { Tabs } from "antd";

const CourseDetailScreen = (props) => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const ids = id.split('-');
    if (ids.length !== 3) {
        navigateTo(MainLayoutUrl.course.index.path);
        return;
    }
    const catalogId = ids[0];
    const chapterId = ids[1];
    const courseId = ids[2];
    const categoryOptions = [
        { key: '1', value: 'basic', label: 'Basic Info' },
        { key: '2', value: 'questions', label: 'Questions' },
    ];
    const [data, setData] = useState();

    useEffect(() => {
        if (courseId && courseId !== '0') {
            fetchData();
        }
        return () => {
            window.sessionStorage.setItem('tmp-catalog', catalogId);
            window.sessionStorage.setItem('tmp-chapter', chapterId);
        }
    }, [])

    const fetchData = () => {
        let params = {
            'page.disable': true,
            'catalogId': catalogId,
            'chapterId': chapterId
        }
        getCourseItems(params).then(res => {
            if (res.list && res.list.length) {
                let detail = res.list.find(item => item.id === courseId);
                if (detail) setData({ ...detail });
            }
        })
    }

    const onClose = () => {
        navigateTo(MainLayoutUrl.course.index.path);
    }

    const tabItems = () => {
        return categoryOptions.map((item) => {
            let children = <BasicCourseView catalogId={catalogId} chapterId={chapterId} defaultCourse={data} onClose={onClose} />;
            if (item.value === 'questions') {
                children = <CourseQuestionView courseId={courseId} onClose={onClose} />
            }
            let disabled = false;
            if (item.value === 'questions' && courseId === '0') {
                disabled = true;
            }
            return {
                label: item.label,
                key: item.value,
                children: children,
                disabled: disabled,
            }
        })
    }

    return <div style={{ padding: '20px 40px' }}>
        <div className="title">{courseId ? (courseId === '0' ? 'Create New Course' : 'Edit Course') : ''}</div>
        <div style={{ height: 12 }} />
        <Tabs items={tabItems()} />
    </div>
}

export default CourseDetailScreen;