import { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Pagination, Typography, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { createCourseCatalog, deleteCourseCatalog, fetchCourseCatalogs, updateCourseCatalog } from '../../api/learning';

const CatalogModalView = ({courseId, courseTitle, onClose, open}) => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Catalog Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let val = record.name;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, name: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, record) => {
                let val = record.weight;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, weight: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
        },
        // {
        //     title: 'Articles Count',
        //     dataIndex: 'articleCount',
        //     key: 'articleCount',
        //     render: (text, _) => {
        //         return text
        //     },
        // },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this course catalog?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link' style={{padding: 0}}>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getCourseCatalogs(pageNum, pageSize);
    }, [])

    const getCourseCatalogs = (pNum, pSize) => {
        setLoading(true);

        let params = {
            'page.num': pNum,
            'page.size': pSize,
            courseId
        }
        fetchCourseCatalogs(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getCourseCatalogs(page, size);
    }

    const editHandle = (params) => {
        setLoading(true);
        if (parseInt(params.id) > 0) {
            updateCourseCatalog(params, courseId).then(res => {
                if (res) {
                    message.success('updated!');
                    getCourseCatalogs(pageNum, pageSize);
                }
            }).finally(() => setLoading(false));
        } else {
            let newParams = { ...params, courseId };
            delete newParams['id'];
            createCourseCatalog(newParams).then(res => {
                if (res) {
                    message.success('created!');
                    getCourseCatalogs(pageNum, pageSize);
                }
            }).finally(() => setLoading(false));
        }
    }

    const addCatalog = () => {
        if (tabData.findIndex(item => item.id === '-1') >= 0) return;
        setTabData([
            {
                id: '-1',
                name: '',
                weight: 0,
            }, ...tabData
        ]);
    }

    const deleteHandle = (record) => {
        if(parseInt(record.id) <= 0) {
            setTabData([...tabData].filter(old => old !== record));
            return;
        }
        setLoading(true);
        deleteCourseCatalog(record.id).then(res => {
            if (res) {
                message.success('deleted!');
                getCourseCatalogs(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Modal width={640} title={`《${courseTitle}》Catalogs`} open={open} onCancel={onClose} footer={null} styles={{body: {padding: 0}}}>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={addCatalog}>Create Course Catalog</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </Modal>
    )
}

export default CatalogModalView;