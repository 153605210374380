import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useMemo } from 'react';
import { createNews, updateNews } from "../../api/news";
import { Descriptions, Input, Space, Button, message } from 'antd'
import RichTextEditor from "../../components/editor";
import { CloudStorageImageUploader } from "../../components/upload";
import { MainLayoutUrl } from "../../router/router_path";
import { customUploaderBtn } from "../../components/comm/custom_uploader_btn";

const NewsDetailScreen = (props) => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const [data, setData] = useState(location.state && location.state.detail ? JSON.parse(location.state.detail) : {});
    const [loading, setLoading] = useState(false);

    const onFormChange = (tag, value) => {
        let newData = { ...data };
        newData[tag] = value;
        setData(newData);
    }

    const onImageUpdate = (files) => {
        let newData = { ...data };
        newData.resource = (files ?? []).map(item => (item.remoteUrl ? item.remoteUrl : item.url)).join(',');
        setData(newData);
    }

    const toSubmit = () => {
        setLoading(true);
        let params = { ...data };
        if (params.id) {
            delete params['createdAt'];
            updateNews(params).then(res => {
                if(res) {
                    message.success('updated!');
                    onClose();
                }
            }).catch((error) => {
                message.error(String(error));
            }).finally(() => {
                setLoading(false);
            });
        } else {
            createNews(params).then(res => {
                if(res) {
                    message.success('updated!');
                    onClose();
                }
            }).catch((error) => {
                message.error(String(error));
            }).finally(() => {
                setLoading(false);
            });
        }

    }

    const onClose = () => {
        navigateTo(MainLayoutUrl.news.index.path);
    }

    const loaderProps = {
        defaultFileList: useMemo(() => {
            return (data.resource ?? '').split(',').filter(item => item).map(item => ({
                name: (decodeURIComponent(item).split('/').pop() ?? '').split('?')[0],
                url: item,
                percent: 100,
            }));
        }, [data.resource]),
        name: 'community-uploader',
        bucketPath: '/community',
        listType: 'picture-card',
        isMultiple: false,
        needItemRender: true,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Media'),
        onUpdate: onImageUpdate
    }

    return (
        <div style={{ padding: '20px 40px' }}>
            <div className="title">{id ? (id === '0' ? 'Publish News' : 'Edit News') : ''}</div>
            <div style={{ height: 30 }} />
            <Descriptions size='large' bordered column={2} labelStyle={{ width: 150 }}>
                <Descriptions.Item label='Title' span={2}>
                    <Input value={data.title} placeholder="news title" onChange={evt => onFormChange('title', evt.target.value)} allowClear />
                </Descriptions.Item>
                <Descriptions.Item label='Weight' span={1}>
                    <Input value={data.weight} placeholder="weight" onChange={evt => onFormChange('weight', evt.target.value)} allowClear />
                </Descriptions.Item>
                <Descriptions.Item label='Link' span={1}>
                    <Input value={data.link} placeholder="extra link" onChange={evt => onFormChange('link', evt.target.value)} allowClear />
                </Descriptions.Item>
                <Descriptions.Item label='Resource' span={2}>
                    <Space align="center">
                        <CloudStorageImageUploader {...loaderProps} />
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label='Content' span={2}>
                    <RichTextEditor
                        defaultHeight={500}
                        defaultContent={data.content}
                        contentField='content'
                        onUpdate={(field, result) => onFormChange(field, result)} />
                </Descriptions.Item>
            </Descriptions>
            <div className="btn-area">
                <Button loading={loading} type="primary" style={{ width: 200, height: 44 }} onClick={toSubmit}>Submit</Button>
                <div style={{ width: 24 }} />
                <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={onClose}>Cancel</Button>
            </div>
        </div>
    )
}

export default NewsDetailScreen;