import { Upload } from "antd";
import { firebaseConfig, BucketType } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { message } from "antd";
import React, { useEffect, useState } from "react";
import BannerItemRender from "./banner_item_render";
import SquareItemRender from "./square_item_render";
import { reAuthenticationForStorage } from "../../utils/request";
import { uuid } from "../../utils/comm";

const CloudStorageImageUploader = ({
    buttonNode,
    name,
    showUploadList,
    defaultFileList,
    listType,
    isMultiple,
    bucketPath,
    needItemRender = true,
    useCardPreview = false,
    onUpdate,
}) => {
    const [fileList, setFileList] = useState([]);
    
    useEffect(() => {
        setFileList(defaultFileList ?? []);
    }, [defaultFileList]);

    const handleUpload = (options) => {
        let file = options.file;
        let fileName = `${uuid()}-${file.name}`;
        let storage = firebaseConfig(String(file.type).includes('video') ? BucketType.video : BucketType.image);
        const storageRef = ref(storage, `${bucketPath}/${encodeURIComponent(fileName.replace(/[ !@#$%^&*(),?":{}|<>]/g, '-'))}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                if (isMultiple) {
                    let fileData = fileList.find((ele) => ele.uid === file.uid);
                    fileData.percent = percent;
                    if (percent == 100) {
                        fileData.status = 'done';
                    }
                    setFileList([...fileList]);
                } else {
                    fileList[0].percent = percent;
                    if (percent == 100) {
                        fileList[0].status = 'done';
                    }
                    setFileList([...fileList]);
                }
            },
            (err) => {
                message.error(String(err));
                reAuthenticationForStorage(() => {
                    uploadTryAgain(options)
                })
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    let updated = url;
                    if (isMultiple) {
                        let fileData = fileList.find((ele) => ele.uid === file.uid);
                        fileData.remoteUrl = updated;
                        setFileList([...fileList]);
                    } else {
                        fileList[0].remoteUrl = updated;
                        setFileList([...fileList]);
                    }

                    if (onUpdate) onUpdate([...fileList]);
                });
            }
        );
    }

    const uploadTryAgain = (options) => {
        let file = options.file;
        let fileName = `${uuid()}-${file.name}`;
        let storage = firebaseConfig(String(file.type).includes('video') ? BucketType.video : BucketType.image);
        const storageRef = ref(storage, `${bucketPath}/${encodeURIComponent(fileName.replace(/[ !@#$%^&*(),?":{}|<>]/g, '-'))}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                if (isMultiple) {
                    let fileData = fileList.find((ele) => ele.uid === file.uid);
                    fileData.percent = percent;
                    if (percent == 100) {
                        fileData.status = 'done';
                    }
                    setFileList([...fileList]);
                } else {
                    fileList[0].percent = percent;
                    if (percent == 100) {
                        fileList[0].status = 'done';
                    }
                    setFileList([...fileList]);
                }
            },
            (err) => {
                message.error(String(err));
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    let updated = url;
                    if (isMultiple) {
                        let fileData = fileList.find((ele) => ele.uid === file.uid);
                        fileData.remoteUrl = updated;
                        setFileList([...fileList]);
                    } else {
                        fileList[0].remoteUrl = updated;
                        setFileList([...fileList]);
                    }

                    if (onUpdate) onUpdate([...fileList]);
                });
            }
        );
    }

    const onDelete = (fileUid) => {
        let files = fileList.filter((file) => file.uid !== fileUid);
        setFileList([...files]);
        if (onUpdate) onUpdate([...files]);
    }

    const onActionChange = (file, value) => {
        let updated = fileList.find((item) => item.uid === file.uid || (file.id && file.id === item.id));
        updated.action = value;
        setFileList([...fileList]);
        if (onUpdate) onUpdate([...fileList]);
    }

    return (
        <Upload
            name={name}
            multiple={isMultiple}
            showUploadList={showUploadList}
            listType={listType}
            beforeUpload={(file, files) => {
                if (isMultiple) {
                    let datas = [...files].map((ele) => ({
                        name: ele.name,
                        url: (window.URL || window.webkitURL).createObjectURL(ele),
                        uid: ele.uid,
                        type: ele.type,
                        size: ele.size,
                        action: '',
                        percent: 0,
                        remoteUrl: '',
                        status: 'uploading',
                    }));
                    setFileList([...fileList, ...datas]);
                } else {
                    let data = {
                        name: file.name,
                        url: (window.URL || window.webkitURL).createObjectURL(file),
                        uid: file.uid,
                        type: file.type,
                        size: file.size,
                        action: '',
                        percent: 0,
                        remoteUrl: '',
                        status: 'uploading',
                    }
                    setFileList([data]);
                }
                return file;
            }}
            fileList={fileList}
            customRequest={handleUpload}
            onChange={(info) => {
                console.log('info changed', info);
            }}
            onRemove={(file) => onDelete(file.uid)}
            itemRender={needItemRender ? ((node, file) => {
                if(useCardPreview) {
                    return <SquareItemRender file={file} onDelete={onDelete} onActionChange={onActionChange} />;
                }
                return <BannerItemRender file={file} onDelete={onDelete} onActionChange={onActionChange} />;
            }) : null} >
            {buttonNode}
        </Upload>
    )
}

export default React.memo(CloudStorageImageUploader);