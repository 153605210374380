import { useParams } from "react-router-dom";
import React, { useState, useMemo } from 'react';
import '../../style/discover.css';
import { Button, Input, message } from "antd";
import { useLocation } from "react-router-dom";
import { createDailyKnowledge, updateDailyKnowledge } from "../../api";
import { MainLayoutUrl } from "../../router/router_path";
import { useNavigate } from "react-router-dom";
import { CloudStorageImageUploader } from "../../components/upload";
import { customUploaderBtn } from "../../components/comm/custom_uploader_btn";

const DailyDetailScreen = (props) => {
    const navigateTo = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const [data, setData] = useState(location.state && location.state.detail ? JSON.parse(location.state.detail) : {});
    const [loading, setLoading] = useState(false);

    const onFormChange = (key, value) => {
        let updated = { ...data };
        updated[key] = value;
        setData(updated);
    }

    const toSubmit = () => {
        setLoading(true);
        let params = { ...data };
        if (data.id) {
            updateDailyKnowledge(params).then(res => {
                setLoading(false);
                navigateTo(MainLayoutUrl.daily.knowledge.index.path);
            }).catch(err => {
                setLoading(false);
                message.error(String(err));
            });
        } else {
            createDailyKnowledge(params).then(res => {
                setLoading(false);
                navigateTo(MainLayoutUrl.daily.knowledge.index.path);
            }).catch(err => {
                setLoading(false);
                message.error(String(err));
            });
        }

    }

    const toCancel = () => {
        navigateTo(MainLayoutUrl.daily.knowledge.index.path);
    }

    const onImageUpdate = (files) => {
        if (!files || files.length <= 0) return;
        let newData = { ...data, previewImage: files[0].remoteUrl };
        setData(newData);
    }

    const dailyUploadProps = {
        name: 'daily-uploader',
        bucketPath: '/daily',
        defaultFileList: useMemo(() => {
            return data && data.previewImage ? [{
                name: (decodeURIComponent(data.previewImage).split('/').pop() ?? '').split('?')[0],
                url: data.previewImage,
                percent: 100,
            }] : [];
        }, [data.previewImage]),
        showUploadList: { showRemoveIcon: false },
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Image'),
        onUpdate: (data) => onImageUpdate(data),
    }

    return <div style={{ padding: '20px 40px' }}>
        <div className="title">{id ? (id === '0' ? 'Create New Daily Knowledge' : 'Edit Daily Knowledge') : ''}</div>
        <div style={{ height: 30 }} />
        <div className="input-item">
            <div className="lab">Title</div>
            <div className="input">
                <Input value={data.title} placeholder="please input knowledge keywords" onChange={(evt) => onFormChange('title', evt.target.value)} showCount maxLength={50} />
            </div>
        </div>
        <div className="input-item">
            <div className="lab">Keyword</div>
            <div className="input">
                <Input value={data.keyword} placeholder="please input knowledge keywords" onChange={(evt) => onFormChange('keyword', evt.target.value)} showCount maxLength={50} />
            </div>
        </div>
        <div className="input-item">
            <div className="lab">Preview Image</div>
            <div className="input">
                <CloudStorageImageUploader {...dailyUploadProps} />
            </div>
        </div>
        <div className="input-item">
            <div className="lab">Content</div>
            <div className="input">
                <Input.TextArea rows={6} value={data.content} placeholder="please input knowledge detail" onChange={(evt) => onFormChange('content', evt.target.value)} showCount maxLength={1500} />
            </div>
        </div>
        <div className="btn-area">
            <Button type="primary" style={{ width: 200, height: 44 }} loading={loading} onClick={toSubmit}>Submit</Button>
            <div style={{ width: 24 }} />
            <Button block style={{ width: 200, height: 44 }} loading={loading} onClick={toCancel}>Cancel</Button>
        </div>
    </div>
}

export default DailyDetailScreen;