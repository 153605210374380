import { useEffect, useState, useRef } from 'react'
import { deleteWhitelistUser, fetchWhitelistUsers } from '../../api/community';
import { Button, Popconfirm, Table, Pagination } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { message } from 'antd';
import UserInfoView from '../../components/community/user_info_view';
import WhitelistUserModalView from '../../components/user/whiteilist_user_modal_view';

const WhitelistUserScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'User Info',
            dataIndex: 'user',
            key: 'user',
            render: (_, record) => {
                let {user} = record;
                return (
                   <UserInfoView user={user} />
                );
            }
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this whitelist user?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link' style={{padding: 0}}>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getWhitelistUsers(pageNum, pageSize);
    }, [])

    const getWhitelistUsers = (pNum, pSize) => {
        setLoading(true);

        let params = {
            'page.num': pNum,
            'page.size': pSize,
        }
        fetchWhitelistUsers(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getWhitelistUsers(page, size);
    }

    const addNewWhitelistUser = () => {
        setOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteWhitelistUser(record.id).then(res => {
            if(res) {
                message.success('deleted!');
                getWhitelistUsers(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={addNewWhitelistUser}>Add Whitelist User For Approval</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <WhitelistUserModalView
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onUpdate={() => {
                    getWhitelistUsers(pageNum, pageSize);
                }}
            />
        </div>
    )
}

export default WhitelistUserScreen;