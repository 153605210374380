import request from "../utils/request";

export function fetchHomePageHotArticle(params) {
    return request.get('/hot/article', params);
}

export function createHomePageHotArticle(params) {
    return request.post('/hot/article/create', params);
}

export function updateHomePageHotArticle(params) {
    return request.patch('/hot/article/edit', params);
}

export function deleteHomePageHotArticle(id) {
    return request.dataDel(`/hot/article?id=${id}`);
}

export function fetchHomePageHotTag(params) {
    return request.get('/hot/tag', params);
}

export function createHomePageHotTag(params) {
    return request.post('/hot/tag/create', params);
}

export function updateHomePageHotTag(params) {
    return request.patch('/hot/tag/edit', params)
}

export function deleteHomePageHotTag(id) {
    return request.dataDel(`/hot/tag?id=${id}`);
}

export function fetchHotTopicPosts(params) {
    return request.get('/hot/tag/post', params)
}

export function createHotTopicPost(params) {
    return request.post('/hot/tag/post/create', params)
}

export function updateHotTopicPost(params) {
    return request.patch('/hot/tag/post/edit', params)
}

export function deleteHotTopicPost(id) {
    return request.dataDel(`/hot/tag/post?id=${id}`)
}