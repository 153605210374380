import { useEffect, useState } from 'react'
import { Button, Table, Pagination, Flex, Space, Select, Popconfirm, Tag, Statistic, Typography } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { conversionUtcDate } from '../../utils/comm';
import { PlusOutlined } from '@ant-design/icons';
import { deletePMTopPicks, fetchPMTopPicks, updatePMTopPicks } from '../../api/privatemarket';
import PicksModalView from '../../components/privatemarket/picks_modal_view';

const PMTopPicksScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [currData, setCurrData] = useState(null);
    const [searchParams, setSearchParams] = useState({
        status: '',
    });

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Product ID',
            dataIndex: 'productId',
            key: 'productId',
            render: (_, record) => {
                const {product} = record;
                return <Typography.Text copyable>{product.id}</Typography.Text>
            },
        },
        {
            title: 'Product Name',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                const {product} = record;
                return <div style={{ width: 200 }}><b>{product.name}</b></div>
            },
        },
        {
            title: 'Code',
            dataIndex: 'code',
            key: 'code',
            render: (_, record) => {
                const {product} = record;
                return <span style={{ whiteSpace: 'nowrap' }}>{product.code}</span>
            }
        },
        {
            title: 'Info1',
            dataIndex: 'info1',
            key: 'info1',
            render: (_, record) => {
                const {product} = record;
                return <Statistic title={<div style={{ whiteSpace: 'nowrap' }}>{product.info1}</div>} value={product.infoNumber1} valueStyle={{ fontSize: 16, whiteSpace: 'nowrap', color: '#87d068' }} />
            }
        },
        {
            title: 'Info2',
            dataIndex: 'info2',
            key: 'info2',
            render: (_, record) => {
                const {product} = record;
                return <Statistic title={<div style={{ whiteSpace: 'nowrap' }}>{product.info2}</div>} value={product.infoNumber2} valueStyle={{ fontSize: 16, whiteSpace: 'nowrap', color: '#87d068' }} />
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (String(text) === '0') return <Tag color='success'>Active</Tag>;
                return <Tag color='warning'>Inactive</Tag>
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                return <b>{text}</b>
            }
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                if (!text) return '-';
                return <span style={{ fontSize: 12, color: '#afafaf' }}>{conversionUtcDate(text, null, true)}</span>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Button type='link' onClick={() => { activeHandle(record) }}>{String(record.status) === '0' ? 'Inactive' : 'Active'}</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this top-picks?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            },
            fixed: 'right'
        }
    ];

    useEffect(() => {
        getTopPicks(pageNum, pageSize, searchParams);
    }, [])

    const getTopPicks = useDebouncedCallback(
        (pNum, pSize, search) => {
            let params = {
                'page.num': pNum,
                'page.size': pSize,
                ...search
            }
            setLoading(true);
            fetchPMTopPicks(params).then(res => {
                if (res.list) {
                    setTabData(res.list);
                }
                if (res.page) {
                    setTotal(res.page.total);
                }
            }).finally(() => setLoading(false))
        },
        300
    )

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getTopPicks(page, size, searchParams);
    }

    const editHandle = (record) => {
        setCurrData(record);
        setModalOpen(true);
    }

    const createNewPicks = () => {
        setCurrData(null);
        setModalOpen(true);
    }

    const activeHandle = (item) => {
        let params = { ...item, status: String(item.status) === '0' ? 1 : 0, };
        setLoading(true);
        updatePMTopPicks(params).then(res => {
            if(res) {
                getTopPicks(pageNum, pageSize);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deletePMTopPicks(record.id).then(res => {
            if (res) {
                getTopPicks(pageNum, pageSize, searchParams);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            getTopPicks(1, pageSize, newParams);
        },
        300
    )

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={createNewPicks}>Add Picks</Button>
                    <Space>
                        <Select
                            style={{ width: 160 }}
                            options={[
                                { key: 1, value: 0, label: 'Active' },
                                { key: 1, value: 1, label: 'Inactive' },
                            ]}
                            placeholder='Status'
                            onChange={value => onSearchChange('status', value)}
                            allowClear
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table scroll={{ x: true }} loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <PicksModalView
                oldData={currData}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onUpdate={() => { getTopPicks(pageNum, pageSize, searchParams) }} />
        </div>
    )
}

export default PMTopPicksScreen;