import { Input, Button, Select, Form, Row, Col, Space, Radio, Modal, Spin, Avatar, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { createCourseModuleArticle, updateCourseModuleArticle } from '../../api/learning';
import { useDebouncedCallback } from 'use-debounce';
import { fetchPosts } from '../../api/community';
import { conversionUtcDate } from '../../utils/comm';
import { UserOutlined } from '@ant-design/icons';
import { CloudStorageImageUploader } from '../upload';
import { customUploaderBtn } from '../comm/custom_uploader_btn';

const ArticleEditModalView = ({ moduleId, defaultArticle, onClose, onUpdate, open }) => {
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [articles, setArticles] = useState([]);
    const [cover, setCover] = useState();

    useEffect(() => {
        if (defaultArticle) {
            form.setFieldValue('postId', defaultArticle.postId)
            form.setFieldValue('status', defaultArticle.status)
            form.setFieldValue('site', defaultArticle.site)
            setCover(defaultArticle.cover);

            if(defaultArticle.post) {
                form.setFieldValue('title', defaultArticle.post.title)
                setArticles([{
                    key: defaultArticle.post.id,
                    value: defaultArticle.post.id,
                    label: defaultArticle.post.title,
                    createdAt: conversionUtcDate(defaultArticle.post.createdAt, null, true)
                }])
            }
        } else {
            handleSearch();
            resetFields();
        }
    }, [open]);

    const resetFields = () => {
        form.setFieldValue('postId', null)
        form.setFieldValue('status', null)
        form.setFieldValue('site', '')
        form.setFieldValue('title', '')
        setCover(null);
    }

    const onSubmit = () => {
        let params = {
            moduleId,
            postId: form.getFieldValue('postId'),
            status: form.getFieldValue('status'),
            site: form.getFieldValue('site'),
            cover: cover
        };
        setLoading(true);
        if (defaultArticle?.id) {
            updateCourseModuleArticle({ ...params, id: defaultArticle.id }).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createCourseModuleArticle(params).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const drawTitle = () => {
        if (defaultArticle) {
            return 'Edit module article'
        }
        return 'Add new module article'
    }

    const handleArticleKeywordSearch = (keyword) => {
        handleSearch('keyword', keyword)
    }

    const handleSearch = useDebouncedCallback((flag, value) => {
        setSearchLoading(true);
        let params = {
            'page.num': 1,
            'page.size': 10,
            'status': 1,
            'categoryId': '2',
            // 'isCourse': true
        }
        if (flag && value) {
            params[flag] = value;
        }
        fetchPosts(params).then(res => {
            if (res.list) {
                let tmp = res.list.map(item => {
                    let label = item.title;
                    return {
                        avatar: (item.user.picture ? item.user.picture : item.user.avatar),
                        createdAt: conversionUtcDate(item.createdAt, null, true),
                        label: label,
                        value: item.id,
                    }
                });
                setArticles(tmp);
            }
        }).finally(() => {
            setSearchLoading(false);
        });
    }, 500)

    const onArticleChange = (value) => {
        let post = articles.find(item => item.value === value);
        if (post) {
            form.setFieldValue('title', post.label);
        }
    }

    const onCoverImageUpdate = (files) => {
        if (files[0]) {
            setCover(files[0].remoteUrl ? files[0].remoteUrl : files[0].url)
        } else {
            setCover(null)
        }
    }

    const moduleCoverUploadProps = {
        defaultFileList: useMemo(() => {
            return cover ? [{
                name: (decodeURIComponent(cover).split('/').pop() ?? '').split('?')[0],
                url: cover,
                percent: 100,
            }] : [];
        }, [cover]),
        name: 'course-module-cover-uploader',
        bucketPath: '/course-module-cover',
        listType: 'picture-card',
        isMultiple: false,
        needItemRender: false,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Cover'),
        onUpdate: onCoverImageUpdate
    }

    return (
        <div>
            <Modal
                title={drawTitle()}
                width={480}
                mask
                onCancel={onClose}
                open={open}
                styles={{
                    body: {
                        paddingTop: 32,
                    },
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='postId' label='Search Course Article'>
                                <Select
                                    disabled={defaultArticle?.id}
                                    style={{ width: '100%' }}
                                    size='large'
                                    placeholder='you can input keywords to search...'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleArticleKeywordSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={articles}
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                {
                                                    option.data.avatar ?
                                                        <Avatar size={32} src={option.data.avatar} />
                                                        :
                                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                }
                                                <div style={{ flex: 1 }}>
                                                    <b style={{ wordBreak: 'break-word' }}>{option.data.label ? option.data.label : 'anonymous'}</b>
                                                    <div>
                                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                            {option.data.createdAt}
                                                        </Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    onChange={onArticleChange}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='postId' label='Article ID'>
                                <Input placeholder='article id' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='title' label='Article Title'>
                                <Input disabled placeholder='article title' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='cover' label='Cover'>
                                <CloudStorageImageUploader {...moduleCoverUploadProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='site' label='Site'>
                                <Input placeholder='site' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='status' label='Status'>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Active</Radio.Button>
                                    <Radio.Button value={1}>Inactive</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default ArticleEditModalView;