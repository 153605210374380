import { useEffect, useState } from "react";
import { Pagination, Button, Space, Descriptions, Popconfirm, Table, Typography, Tag, Input, Select, DatePicker } from "antd";
import PostReview from "../../components/community/post_review";
import dayjs from "dayjs";
import UserInfoView from "../../components/community/user_info_view";
import { useDebouncedCallback } from "use-debounce";
import { deletePMInsights, fetchPMInsights, updatePMInsights } from "../../api/privatemarket";
import InsightsModalView from "../../components/privatemarket/insights_modal_view";

const PMInsightsCoverScreen = () => {
    const [posts, setPosts] = useState([]);
    const [open, setOpen] = useState(false);
    const [currPost, setCurrPost] = useState(null);
    const [reviewOpen, setReviewOpen] = useState(false);
    const [searchParams, setSearchParams] = useState({
        status: '',
    });
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'PostId',
            dataIndex: 'id',
            key: 'id',
            render: (_, record) => {
                const { post } = record;
                return <div style={{ width: 80 }}><Typography.Text copyable style={{ fontSize: 11, color: '#afafaf' }}>{post.id}</Typography.Text></div>
            },
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (_, record) => {
                const { post } = record;
                return <UserInfoView user={post.user} />
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => {
                const { post } = record;
                return <b>{post.title}</b>
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                if (!text) return '-';
                return <b>{text}</b>
            },
        },
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => {
                const { post } = record;
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(post.createdAt).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (String(text) === '0') return <Tag color='success'>Active</Tag>;
                return <Tag color='warning'>Inactive</Tag>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { reviewHandle(record) }}>Review Post</Button>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Button type='link' onClick={() => { activeHandle(record) }}>{String(record.status) === '0' ? 'Inactive' : 'Active'}</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to remove this insights-cover?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            },
        }
    ];

    useEffect(() => {
        getData(pageNum, pageSize, searchParams);
    }, [])

    const getData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search
        }
        fetchPMInsights(params).then(res => {
            if (res.list) {
                setPosts(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
            setLoading(false);
        })
    }

    const activeHandle = (item) => {
        let params = { ...item, status: String(item.status) === '0' ? 1 : 0, };
        setLoading(true);
        updatePMInsights(params).then(res => {
            if (res) {
                getData(pageNum, pageSize, searchParams);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const editHandle = (record) => {
        setCurrPost(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deletePMInsights(record.id).then(res => {
            if (res) {
                getData(pageNum, pageSize, searchParams);
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    const reviewHandle = (record) => {
        setCurrPost(record);
        setReviewOpen(true);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            getData(1, pageSize, newParams);
        },
        300
    )

    const getExtraView = () => {
        return (
            <Space>
                <Button type="primary" onClick={() => editHandle(null)}>Add New Insights-Cover</Button>
            </Space>
        )
    }

    const getTitleView = () => {
        return (
            <Space>
                <Select
                    style={{ width: 160 }}
                    options={[
                        { key: 1, label: 'Active', value: 0 },
                        { key: 2, label: 'Inactive', value: 1 },
                    ]}
                    placeholder='status'
                    onChange={value => onSearchChange('status', value)}
                    allowClear
                />
            </Space>
        )
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getData(page, size, searchParams);
    }

    return (
        <div style={{ padding: 20 }}>
            <Descriptions title={getExtraView()} extra={getTitleView()} column={3}>
                <Descriptions.Item span={3}>
                    <Table
                        style={{ width: '100%' }}
                        loading={loading}
                        rowKey={(record) => record.post.id}
                        rowClassName={'table-row'}
                        size='small'
                        dataSource={posts}
                        columns={tabCols}
                        pagination={false} />
                </Descriptions.Item>
            </Descriptions>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
            <InsightsModalView
                open={open}
                oldData={currPost}
                onClose={() => setOpen(false)}
                onUpdate={() => getData(pageNum, pageSize, searchParams)} />
            <PostReview
                from="discoverhotpost"
                open={reviewOpen}
                post={currPost ? currPost.post : null}
                onClose={() => setReviewOpen(false)}
                canEdit={false} />
        </div>
    );
}
export default PMInsightsCoverScreen;