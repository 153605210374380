import { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Pagination, Tag, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import dayjs from 'dayjs';
import { deleteCourseModule, fetchCourseModules, updateCourseModule } from '../../api/learning';
import { useNavigate } from 'react-router-dom';
import { MainLayoutUrl } from '../../router/router_path';

const LearningCenterScreen = () => {
    const navigateTo = useNavigate();
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Module Name',
            dataIndex: 'moduleName',
            key: 'moduleName',
            render: (text, _) => {
                return <b>{text}</b>
            },
        },
        {
            title: 'Module Type',
            dataIndex: 'moduleType',
            key: 'moduleType',
            render: (text, _) => {
                return <Tag>{text}</Tag>
            },
        },
        {
            title: 'Display Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, _) => {
                return <b>{text}</b>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (String(text) === '0') return <Tag color='success'>Active</Tag>;
                return <Tag color='warning'>Inactive</Tag>
            },
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text, _) => {
                return <Typography.Text copyable style={{ fontSize: 12, color: '#afafaf' }}>{text}</Typography.Text>
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                return text
            },
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Button type='link' onClick={() => { activeHandle(record) }}>{String(record.status) === '0' ? 'Inactive' : 'Active'}</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this course module?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getCenterModules(pageNum, pageSize);
    }, [])

    const getCenterModules = (pNum, pSize) => {
        setLoading(true);

        let params = {
            'page.num': pNum,
            'page.size': pSize,
        }
        fetchCourseModules(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getCenterModules(page, size);
    }

    const editHandle = (record) => {
        navigateTo(MainLayoutUrl.learning.learningcenter.item.path.replace(':id', record.id), { state: { detail: JSON.stringify(record) } })
    }

    const activeHandle = (record) => {
        setLoading(true);
        updateCourseModule({ ...record, status: record.status === 1 ? 0 : 1 }).then(res => {
            if (res) {
                getCenterModules(pageNum, pageSize);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const addModule = () => {
        navigateTo(MainLayoutUrl.learning.learningcenter.item.path.replace(':id', '0'));
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteCourseModule(record.id).then(res => {
            if (res) {
                message.success('deleted!');
                getCenterModules(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={addModule}>Create Module</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}
export default LearningCenterScreen;