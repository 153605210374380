import { Input, Button, Select, Form, Row, Col, Space, Radio, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { createCourseModuleCourse, fetchCourses, updateCourseModuleCourse } from '../../api/learning';
import { useDebouncedCallback } from 'use-debounce';
const CourseEditModalView = ({ moduleId, defaultCourse, onClose, onUpdate, open }) => {
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [courses, setCourses] = useState([]);

    useEffect(() => {
        if (defaultCourse) {
            form.setFieldValue('courseId', defaultCourse.courseId)
            form.setFieldValue('status', defaultCourse.status)
            form.setFieldValue('site', defaultCourse.site)

            setCourses([{
                key: defaultCourse.course.id,
                value: defaultCourse.course.id,
                label: defaultCourse.course.title,
            }])
        } else {
            handleSearch();
            resetFields();
        }
    }, [open]);

    const resetFields = () => {
        form.setFieldValue('courseId', null)
        form.setFieldValue('status', null)
        form.setFieldValue('site', '')
    }

    const onSubmit = () => {
        let params = {
            moduleId,
            courseId: form.getFieldValue('courseId'),
            status: form.getFieldValue('status'),
            site: form.getFieldValue('site')
        };
        setLoading(true);
        if (defaultCourse?.id) {
            updateCourseModuleCourse({ ...params, id: defaultCourse.id }).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createCourseModuleCourse(params).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const drawTitle = () => {
        if (defaultCourse) {
            return 'Edit module course'
        }
        return 'Add new module course'
    }

    const handleCourseKeywordSearch = (keyword) => {
        handleSearch('title', keyword)
    }

    const handleCourseIdSearch = (id) => {
        handleSearch('id', id)
    }

    const handleSearch = useDebouncedCallback((flag, value) => {
        setSearchLoading(true);
        let params = {
            'page.num': 1,
            'page.size': 10
        }
        if (flag && value) {
            params[flag] = value;
        }
        fetchCourses(params).then(res => {
            if (res.list) {
                setCourses(res.list.map(item => {
                    return {
                        key: item.id,
                        label: item.title,
                        value: item.id
                    }
                }))
            }
        }).finally(() => {
            setSearchLoading(false);
        });
    }, 500)

    return (
        <div>
            <Modal
                title={drawTitle()}
                width={480}
                mask
                onCancel={onClose}
                open={open}
                styles={{
                    body: {
                        paddingTop: 32,
                    },
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='courseId' label='Keyword search for Course'>
                                <Select
                                    disabled={defaultCourse?.id}
                                    style={{ width: '100%' }}
                                    size='large'
                                    placeholder='you can input keywords to search...'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleCourseKeywordSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={courses}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='courseId' label='Course ID'>
                                <Input type='number' placeholder='course id' onChange={(evt) => handleCourseIdSearch(evt.target.value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='site' label='Site'>
                                <Input placeholder='site' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='status' label='Status'>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Active</Radio.Button>
                                    <Radio.Button value={1}>Inactive</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default CourseEditModalView;