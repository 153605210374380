import { PlusOutlined } from "@ant-design/icons";
import { Space, Flex, Button, Table, Pagination, Popconfirm, Typography, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { fetchCourseArticles, updateCourseArticle } from '../../api/learning'
import CatalogModalView from "./catalog_modal_view";
import CourseAddArticleModalView from "./course_add_article_modal_view";

const CourseArticleTabView = ({ courseId, courseTitle }) => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currArticle, setCurrArticle] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [catalogModalOpen, setCatalogModalOpen] = useState(false);

    const tabCols = [
        {
            title: 'Article ID',
            dataIndex: 'index',
            key: 'index',
            render: (_, record) => {
                const { post } = record;
                return <Typography.Text copyable style={{ fontSize: 12, color: '#afafaf' }}>{post.id}</Typography.Text>
            }
        },
        {
            title: 'Article Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => {
                const { post } = record;
                return post.title;
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                return <b>{text}</b>;
            },
        },
        {
            title: 'Catalog',
            dataIndex: 'catalog',
            key: 'catalog',
            render: (_, record) => {
                if(record.catalogId === '0') return ''
                const { catalog } = record;
                return <Tag>{catalog.name}</Tag>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to unbind this article?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Unbind</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getArticles(pageNum, pageSize)
    }, [])

    const deleteHandle = (record) => {
        setLoading(true);
        let params = {id: record.id};
        params.courseId = '0';
        params.catalogId = '0';
        params.weight = '0';
        updateCourseArticle(params).then(res => {
            if(res) {
                getArticles(pageNum, pageSize);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const editHandle = (record) => {
        setCurrArticle(record);
        setModalOpen(true);
    }

    const getArticles = (pNum, pSize) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            sort: 1,
            courseId,
        };
        fetchCourseArticles(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const checkCatalog = () => {
        setCatalogModalOpen(true);
    }

    const addArticleHandle = () => {
        setCurrArticle(null);
        setModalOpen(true);
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getArticles(page, size);
    }

    return (
        <div>
            <Flex justify="flex-end">
                <Space>
                    <Button icon={<PlusOutlined />} type="primary" onClick={addArticleHandle}>Add Article</Button>
                    <Button onClick={checkCatalog}>Check Course Catalogs</Button>
                </Space>
            </Flex>

            <div className='cnt-item' style={{ padding: '16px 0' }}>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <Flex justify="flex-end">
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </Flex>

            <CatalogModalView
                courseId={courseId}
                courseTitle={courseTitle}
                open={catalogModalOpen}
                onClose={() => setCatalogModalOpen(false)}
            />

            <CourseAddArticleModalView
                courseId={courseId}
                defaultArticle={currArticle}
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onUpdate={() => getArticles(pageNum, pageSize)}
            />
        </div>
    )
}

export default CourseArticleTabView;