import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, useMemo, useContext } from 'react';
import { fetchAllTags, fetchPostCategory, fetchPostCreator, updateCrawledPost, updatePost } from "../../api/community";
import { Descriptions, Input, Select, Space, Button, message, DatePicker, Tag, Typography, Spin, Avatar } from 'antd'
import RichTextEditor from "../../components/editor";
import { CloudStorageImageUploader, CloudStorageVideoUploader } from "../../components/upload";
import { UserInfoProviderContext } from "../../router";
import dayjs from "dayjs";
import { conversionUtcDate } from "../../utils/comm";
import '../../style/community.css';
import VoteItemsEditView from "../../components/community/vote_items_edit_view";
import { useDebouncedCallback } from "use-debounce";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import { H5Options } from "../community/detail";
import { createCourseArticle } from "../../api/learning";

const LearningArticleDetailScreen = () => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const [data, setData] = useState({});
    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(false);

    const [topics, setTopics] = useState([]);
    const [users, setUsers] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);

    const [topicTag, setTopicTag] = useState(null);
    const [contentTags, setContentTags] = useState(null);
    const defaultData = location.state && location.state.detail ? JSON.parse(location.state.detail) : {};

    const isPublishMode = true;
    const userInfo = useContext(UserInfoProviderContext);

    useEffect(() => {
        setData({
            ...defaultData,
            events: Array.from(defaultData.events ?? []).map(item => item.eventType).join(','),
            categoryId: '2',
            userId: defaultData.user?.userId,
        });
        if (defaultData.id) {
            let tTag = Array.from(defaultData.tags).find(item => (item.isTopic === true));
            if (tTag) {
                setTopicTag(tTag.name);
            }
            let cTags = Array.from(defaultData.tags).filter(item => (item.isTopic !== true));
            setContentTags(cTags.map(item => item.name));

            if (defaultData.user) {
                setUsers([
                    {
                        avatar: (defaultData.user.picture ? defaultData.user.picture : defaultData.user.avatar),
                        email: defaultData.user.email,
                        label: defaultData.user.name ? defaultData.user.name : defaultData.user.email,
                        value: defaultData.user.userId,
                    }
                ])
            }
        } else {
            handleUserSearch();
        }

        fetchPostCategory().then(res => {
            if (res.list) {
                setCategories(res.list.map(item => ({ key: item.id, label: item.name, value: item.id })))
            }
        })
    }, [])

    const onFormChange = (tag, value, extra) => {
        let newData = { ...data };
        if (tag.includes('reward')) {
            let reward = { ...(data.reward ?? {}) }
            if (tag.includes('count')) {
                reward['count'] = value;
            } else if (tag.includes('endAt')) {
                reward['endAt'] = conversionUtcDate(value, 'UTC');
            } else if (tag.includes('desc')) {
                reward['description'] = value;
            } else if (tag.includes('tip')) {
                reward['tip'] = value;
            } else if (tag.includes('rule')) {
                reward['rule'] = value;
            } else if (tag.includes('symbol')) {
                reward['symbol'] = value;
            }
            newData['reward'] = reward;
        } else if (tag.includes('vote')) {
            let vote = { ...(data.vote ?? {}) }
            if (tag.includes('items')) {
                vote['options'] = [...value];
            } else if (tag.includes('desc')) {
                vote['description'] = value;
            } else if (tag.includes('endAt')) {
                vote['endAt'] = conversionUtcDate(value, 'UTC');
            }
            if (!vote.id || parseInt(vote.id) <= 0) {
                delete vote['id'];
            }
            newData['vote'] = vote;
        } else {
            if (tag === 'content') {
                let allTags = contentTags ? [...contentTags] : [];
                if (String(value).length < String(newData.content ?? '').length) {
                    let div = document.createElement('div');
                    div.innerHTML = value;
                    let aLabels = div.querySelectorAll('a');
                    let sets = Array.from(aLabels.entries()).map(item => item[1].innerText).filter(item => item);
                    allTags = allTags.filter(item => sets.some(text => {
                        return item.includes(text.replace('#', '')) || item.includes(text.replace('$', ''));
                    }));
                    setContentTags(allTags);
                } else if (extra) {
                    allTags.push(extra);
                    allTags = Array.from(new Set(allTags));
                    setContentTags(allTags);
                }
            }
            newData[tag] = value;
            if (!newData['events']) {
                newData['events'] = ''
            }
        }
        setData(newData);
    }

    const deleteTag = (tag) => {
        let newData = { ...data };

        let content = newData['content'];
        let div = document.createElement('div');
        div.innerHTML = content;
        let aLabels = div.querySelectorAll('a');
        aLabels.forEach(ele => {
            console.log(ele.innerText, tag);
            if (tag.includes(ele.innerText.replace('#', '')) || tag.includes(ele.innerText.replace('$', ''))) {
                ele.remove();
            }
        });
        content = div.innerHTML;
        newData['content'] = content;

        let newContentTags = Array.from(contentTags ?? []).filter(item => item !== tag);
        setContentTags(newContentTags);
        setData(newData);
    }

    const toSubmit = () => {
        setLoading(true);

        let tags = [];
        if (contentTags) {
            tags = [...contentTags];
        }
        if (topicTag) {
            tags = [topicTag, ...tags];
        }
        tags = tags.filter(item => item);
        let newData = { ...data };
        delete newData['adminTags'];
        delete newData['tags'];
        delete newData['topic'];
        if (Array.isArray(newData['events'])) {
            delete newData['events'];
        }
        if (Array.isArray(newData['audioResources'])) {
            delete newData['audioResources'];
        }

        let params = { ...newData, tag: tags };
        if (params.id) {
            updatePost(params.id, params).then(res => {
                if (res) {
                    message.success('updated!');
                    onClose();
                }
            }).catch((error) => {
                message.error(String(error));
            }).finally(() => {
                setLoading(false);
            });
        } else {
            createCourseArticle({ post: { ...params, isCourse: true, } }).then(res => {
                if (res) {
                    message.success('updated!');
                    onClose();
                }
            }).catch((error) => {
                message.error(String(error));
            }).finally(() => {
                setLoading(false);
            });
        }

        if (defaultData?.crawled) {
            updateCrawledPost({ ...defaultData.crawled, status: 1 }).then(res => { })
        }
    }

    const onClose = () => {
        navigateTo(-1);
    }

    const onUpdate = (files) => {
        let url = '';
        if (files[0]) {
            url = files[0].remoteUrl ?? files[0].url
        }
        onFormChange(
            data.courseMediaType === '1' ? 'resource' : 'audioResources',
            url
        );
    }

    const getTopicTags = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'name': keyword,
                'level': 1,
                'isTopicTag': true,
                'status': 0,
            }
            setSearchLoading(true);
            fetchAllTags(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        createdAt: conversionUtcDate(item.createdAt, null, true),
                        label: item.name,
                        value: item.name,
                    }));
                    setTopics(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const handleUserSearch = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'name': keyword,
            }
            setSearchLoading(true);
            fetchPostCreator(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        avatar: (item.picture ? item.picture : item.avatar),
                        email: item.email,
                        label: item.name,
                        value: item.userId,
                    }));
                    setUsers(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    return (
        <div style={{ padding: '20px 40px' }}>
            <div className="title">{id ? (id === '0' ? 'Create New Course Article' : 'Edit Course Article') : ''}</div>
            <div style={{ height: 30 }} />
            <Descriptions size='large' bordered column={2} labelStyle={{ width: 250, textAlign: 'center' }}>
                <Descriptions.Item label='Post Category' span={1}>
                    <Select disabled={isPublishMode} value={data.categoryId} style={{ width: '100%' }} options={categories} placeholder='Select category' onChange={value => onFormChange('categoryId', value)} />
                </Descriptions.Item>
                <Descriptions.Item label='Recommend Weight' span={1}>
                    <Input value={data.recommend} type="number" placeholder="article recommend weight" onChange={evt => onFormChange('recommend', evt.target.value)} />
                </Descriptions.Item>
                <Descriptions.Item label='Title' span={2}>
                    <Input value={data.title} placeholder="article title" onChange={evt => onFormChange('title', evt.target.value)} allowClear />
                </Descriptions.Item>
                <Descriptions.Item label='Topic' span={2}>
                    <Select
                        style={{ width: '100%' }}
                        size='large'
                        placeholder='you can input keywords to search topic'
                        defaultActiveFirstOption={false}
                        value={topicTag}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={(value) => getTopicTags(value)}
                        notFoundContent={searchLoading ? <Spin /> : null}
                        options={topics}
                        optionRender={(option) => {
                            return (
                                <Space>
                                    <div style={{ flex: 1 }}>
                                        <b style={{ wordBreak: 'break-word' }}>{option.data.label}</b>
                                        <div>
                                            <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                {option.data.createdAt}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Space>
                            )
                        }}
                        onChange={(value) => setTopicTag(value)}
                        allowClear
                        showSearch
                    />
                </Descriptions.Item>

                <Descriptions.Item label='Author' span={2}>
                    <Select
                        style={{ width: '100%' }}
                        value={data.userId}
                        disabled={data.id || (userInfo && !userInfo.permission?.menus?.includes('select_author'))}
                        placeholder='search user'
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={handleUserSearch}
                        notFoundContent={searchLoading ? <Spin /> : null}
                        options={users}
                        optionRender={(option) => {
                            return (
                                <Space>
                                    {
                                        option.data.avatar ?
                                            <Avatar size={32} src={option.data.avatar} />
                                            :
                                            <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                    }
                                    <div>
                                        <b>{option.data.label ? option.data.label : 'anonymous'}</b>
                                        <div>
                                            <Typography.Text style={{ color: '#999', fontSize: 12 }}>{option.data.email ?? option.data.value}</Typography.Text>
                                        </div>
                                    </div>
                                </Space>
                            )
                        }}
                        onChange={value => onFormChange('adminUsername', value)}
                        allowClear
                        showSearch
                    />
                </Descriptions.Item>

                <Descriptions.Item label='Course Media Type' span={1}>
                    <Select
                        placeholder='select related media type'
                        value={data.courseMediaType}
                        style={{ width: '100%' }}
                        onChange={(value) => onFormChange('courseMediaType', value)}
                        options={[
                            { value: '0', label: 'Rich Text only' },
                            { value: '1', label: 'Video contained' },
                            { value: '2', label: 'Audio contained' },
                        ]}
                    />
                </Descriptions.Item>
                <Descriptions.Item label='Estimate Read Time' span={1}>
                    <Input placeholder="estimate time" value={data.courseEstimateTime} variant='filled' onChange={(evt) => onFormChange('courseEstimateTime', evt.target.value)} type="number" suffix='Mins' />
                </Descriptions.Item>

                {
                    (data.courseMediaType && parseInt(data.courseMediaType) > 0) && (
                        <Descriptions.Item label='Video or Other Media' span={2}>
                            <div style={{ maxHeight: 150, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                {
                                    data.courseMediaType === '1'
                                        ?
                                        <CloudStorageVideoUploader
                                            defaultVideo={(data.resources ?? [])[0] ? data.resources[0].url : ''}
                                            name={'course_video_uploader'}
                                            bucketPath={'/course/video'}
                                            onUpdate={onUpdate} />
                                        :
                                        <CloudStorageVideoUploader
                                            defaultVideo={(data.audioResources ?? [])[0] ? data.audioResources[0].url : ''}
                                            name={'course_audio_uploader'}
                                            bucketPath={'/course/audio'}
                                            onUpdate={onUpdate} />
                                }
                            </div>
                        </Descriptions.Item>
                    )
                }

                <Descriptions.Item label='Content' span={2}>
                    <RichTextEditor
                        defaultHeight={700}
                        defaultContent={data.content}
                        contentField='content'
                        needEditTags={true}
                        onUpdate={(field, result, tagName) => onFormChange(field, result, tagName)} />

                    <Space wrap={true} style={{ marginTop: 20 }}>
                        {
                            (contentTags ?? []).filter(item => item).map((item, index) => {
                                return <Tag color="blue" key={index} closable onClose={(evt) => {
                                    evt.preventDefault();
                                    deleteTag(item);
                                }}>{`${item.includes('$') ? item : ('#' + item)}`}</Tag>
                            })
                        }
                    </Space>
                </Descriptions.Item>
                <Descriptions.Item label='H5 Action' span={2}>
                    <Select
                        options={H5Options.map(item => {
                            let disabled = false;
                            if (item.value === 'H5GuideLoginFullArticle' && data.categoryId == 1) {
                                disabled = true;
                            }
                            return { ...item, disabled }
                        })}
                        allowClear
                        placeholder='select h5 login action'
                        style={{ width: '100%' }}
                        value={data.events ? data.events : undefined}
                        onChange={(value) => onFormChange('events', value)} />
                </Descriptions.Item>
                <Descriptions.Item label='Votes Config' span={2}>
                    <div className="reward">
                        <Descriptions size="small" column={1} layout="vertical" colon={false} labelStyle={{ marginBottom: 0 }}>
                            <Descriptions.Item label='Vote Desc' span={1}>
                                <Input
                                    style={{ width: 400 }}
                                    placeholder="vote description"
                                    value={data.vote ? data.vote.description : ''}
                                    variant="filled"
                                    onChange={evt => onFormChange('vote_desc', evt.target.value)}
                                    allowClear
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='Vote EndAt' span={1}>
                                <DatePicker
                                    variant="filled"
                                    showTime
                                    minDate={data.vote && data.vote.createdAt ? dayjs(data.vote.createdAt).add(1, 'day') : dayjs(Date.now()).add(1, 'day')}
                                    defaultValue={(defaultData.vote && defaultData.vote.endAt) ? dayjs(defaultData.vote.endAt) : ''}
                                    format={(value) => `${value ? conversionUtcDate(value) : ''}`}
                                    placeholder="End At"
                                    onChange={(_, date) => onFormChange('vote_endAt', date)}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='Vote Items' span={1}>
                                <VoteItemsEditView defaultTags={data.vote && data.vote.options ? data.vote.options : []} onUpdate={(value) => onFormChange('vote_items', value)} />
                            </Descriptions.Item>
                        </Descriptions>
                    </div>

                </Descriptions.Item>
            </Descriptions>
            <div className="btn-area">
                <Button loading={loading} type="primary" style={{ width: 200, height: 44 }} onClick={toSubmit}>Submit</Button>
                <div style={{ width: 24 }} />
                <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={onClose}>Cancel</Button>
            </div>
        </div>
    )
}

export default LearningArticleDetailScreen;