import { Button, Select, Form, Row, Col, Space, Modal, Spin, Avatar, Typography, Input } from 'antd';
import { useEffect, useState } from 'react';
import { addCourseArticle, fetchCourseCatalogs, updateCourseArticle } from '../../api/learning';
import { useDebouncedCallback } from 'use-debounce';
import { fetchPosts } from '../../api/community';
import { conversionUtcDate } from '../../utils/comm';
import { UserOutlined } from '@ant-design/icons';

const CourseAddArticleModalView = ({ courseId, defaultArticle, onClose, onUpdate, open }) => {
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [articles, setArticles] = useState([]);
    const [catalogs, setCatalogs] = useState([]);

    useEffect(() => {
        if(!open) return;
        if (defaultArticle) {
            form.setFieldValue('postId', defaultArticle.postId)
            form.setFieldValue('catalogId', defaultArticle.catalogId === '0' ? null :  defaultArticle.catalogId)
            form.setFieldValue('weight', defaultArticle.weight)
            setArticles([{
                key: defaultArticle.post.id,
                value: defaultArticle.post.id,
                label: defaultArticle.post.title,
                createdAt: conversionUtcDate(defaultArticle.post.createdAt, null, true)
            }])
        } else {
            handleSearch();
            resetFields();
        }
        getCatalogs();
    }, [open]);

    const resetFields = () => {
        form.setFieldValue('postId', null)
        form.setFieldValue('catalogId', null)
        form.setFieldValue('weight', '')
    }

    const onSubmit = () => {
        let params = {
            courseId,
            postId: form.getFieldValue('postId'),
            catalogId: form.getFieldValue('catalogId') ?? '0',
            weight: form.getFieldValue('weight'),
        };
        setLoading(true);
        if (defaultArticle?.id) {
            updateCourseArticle({ ...params, id: defaultArticle.id }).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            addCourseArticle(params).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const drawTitle = () => {
        return 'Edit Course Article Catalog';
    }

    const getCatalogs = () => {
        fetchCourseCatalogs({courseId, 'page.disable': true}).then(res => {
            if(res.list) {
                setCatalogs(res.list.map(item => {
                    return {
                        key: item.id,
                        label: item.name,
                        value: item.id
                    }
                }))
            }
        })
    };

    const handleArticleKeywordSearch = (keyword) => {
        handleSearch('keyword', keyword)
    }

    const handleSearch = useDebouncedCallback((flag, value) => {
        setSearchLoading(true);
        let params = {
            'page.num': 1,
            'page.size': 10,
            'status': 1,
            'isCourse': true
        }
        if (flag && value) {
            params[flag] = value;
        }
        fetchPosts(params).then(res => {
            if (res.list) {
                let tmp = res.list.map(item => {
                    let label = item.title;
                    return {
                        avatar: (item.user.picture ? item.user.picture : item.user.avatar),
                        createdAt: conversionUtcDate(item.createdAt, null, true),
                        label: label,
                        value: item.id,
                    }
                });
                setArticles(tmp);
            }
        }).finally(() => {
            setSearchLoading(false);
        });
    }, 500)

    return (
        <div>
            <Modal
                title={drawTitle()}
                width={560}
                mask
                onCancel={onClose}
                open={open}
                styles={{
                    body: {
                        paddingTop: 32,
                    },
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='postId' label='Course Article'>
                                <Select
                                    size='large'
                                    disabled={defaultArticle && parseInt(defaultArticle.id) > 0}
                                    style={{ width: '100%' }}
                                    placeholder='you can input keywords to search...'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleArticleKeywordSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={articles}
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                {
                                                    option.data.avatar ?
                                                        <Avatar size={32} src={option.data.avatar} />
                                                        :
                                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                }
                                                <div style={{ flex: 1 }}>
                                                    <b style={{ wordBreak: 'break-word' }}>{option.data.label ? option.data.label : 'anonymous'}</b>
                                                    <div>
                                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                            {option.data.createdAt}
                                                        </Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='catalogId' label='Course Catalog'>
                                <Select
                                    size='large'
                                    style={{ width: '100%' }}
                                    placeholder='select course catalog'
                                    options={catalogs}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='weight' label='Weight'>
                                <Input
                                    size='large'
                                    type='number'
                                    style={{ width: '100%' }}
                                    placeholder='weight'
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default CourseAddArticleModalView;