import { Input, Button, Select, Form, Row, Col, Space, Radio, Modal, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { createCourseModuleWiki, updateCourseModuleWiki } from '../../api/learning';
import { fetchDailyKnowledgeList } from '../../api';
import { useDebouncedCallback } from 'use-debounce';
const WikiEditModalView = ({ moduleId, defaultWiki, onClose, onUpdate, open }) => {
    const [searchLoading, setSearchLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [wikis, setWikis] = useState([]);

    useEffect(() => {
        if (defaultWiki) {
            form.setFieldValue('dailyKnowledgeId', defaultWiki.dailyKnowledgeId)
            form.setFieldValue('status', defaultWiki.status)
            form.setFieldValue('site', defaultWiki.site)

            setWikis([{
                key: defaultWiki.knowledge.id,
                value: defaultWiki.knowledge.id,
                label: defaultWiki.knowledge.title,
            }])
        } else {
            handleSearch();
            resetFields();
        }
    }, [open]);

    const resetFields = () => {
        form.setFieldValue('dailyKnowledgeId', null)
        form.setFieldValue('status', null)
        form.setFieldValue('site', '')
    }

    const onSubmit = () => {
        let params = {
            moduleId,
            dailyKnowledgeId: form.getFieldValue('dailyKnowledgeId'),
            status: form.getFieldValue('status'),
            site: form.getFieldValue('site')
        };
        setLoading(true);
        if (defaultWiki?.id) {
            updateCourseModuleWiki({ ...params, id: defaultWiki.id }).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createCourseModuleWiki(params).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const drawTitle = () => {
        if (defaultWiki) {
            return 'Edit module wiki'
        }
        return 'Add new module wiki'
    }

    const handleWikiKeywordSearch = (keyword) => {
        handleSearch('keyword', keyword)
    }

    const handleWikiIdSearch = (id) => {
        handleSearch('id', id)
    }

    const handleSearch = useDebouncedCallback((flag, value) => {
        setSearchLoading(true);
        let params = {
            'page.num': 1,
            'page.size': 10
        }
        if (flag && value) {
            params[flag] = value;
        }
        fetchDailyKnowledgeList(params).then(res => {
            if (res.list) {
                setWikis(res.list.map(item => {
                    return {
                        key: item.id,
                        label: item.title,
                        value: item.id
                    }
                }))
            }
        }).finally(() => {
            setSearchLoading(false);
        });
    }, 500)

    return (
        <div>
            <Modal
                title={drawTitle()}
                width={480}
                mask
                onCancel={onClose}
                open={open}
                styles={{
                    body: {
                        paddingTop: 32,
                    },
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='dailyKnowledgeId' label='Keyword search for Wiki'>
                                <Select
                                    disabled={defaultWiki?.id}
                                    style={{ width: '100%' }}
                                    size='large'
                                    placeholder='you can input keywords to search...'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleWikiKeywordSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={wikis}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='dailyKnowledgeId' label='Wiki ID'>
                                <Input type='number' placeholder='wiki id' onChange={(evt) => handleWikiIdSearch(evt.target.value)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='site' label='Site'>
                                <Input placeholder='site' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='status' label='Status'>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Active</Radio.Button>
                                    <Radio.Button value={1}>Inactive</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default WikiEditModalView;