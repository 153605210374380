import request from "../utils/request";

export function fetchCourses(params) {
    return request.get('/course', params);
}

export function createCourse(params) {
    return request.post('/course/create', params)
}

export function updateCourse(params) {
    return request.patch('/course/edit', params)
}

export function deleteCourse(id) {
    return request.dataDel(`/course?id=${id}`);
}

export function fetchCourseCategories(params) {
    return request.get('/course/category', params)
}

export function createCourseCategory(params) {
    return request.post('/course/category/create', params)
}

export function updateCourseCategory(params) {
    return request.patch('/course/category/edit', params)
}

export function deleteCourseCategory(id) {
    return request.dataDel(`/course/category?id=${id}`);
}

export function fetchCourseCatalogs(params) {
    return request.get('/course/catalog', params)
}

export function createCourseCatalog(params) {
    return request.post('/course/catalog/create', params)
}

export function updateCourseCatalog(params) {
    return request.patch('/course/catalog/edit', params)
}

export function deleteCourseCatalog(id) {
    return request.dataDel(`/course/catalog?id=${id}`);
}

export function fetchCourseArticles(params) {
    return request.get('/course/article', params)
}

export function createCourseArticle(params) {
    return request.post('/course/article/create', params)
}

export function addCourseArticle(params) {
    return request.post('/course/article/add', params)
}

export function updateCourseArticle(params) {
    return request.patch('/course/article/edit', params);
}

export function deleteCouseArticle(id) {
    return request.dataDel(`/course/article?id=${id}`);
}

export function fetchCourseModules(params) {
    return request.get('/course/module', params);
}

export function createCourseModule(params) {
    return request.post('/course/module/create', params);
}

export function updateCourseModule(params) {
    return request.patch('/course/module/edit', params);
}

export function deleteCourseModule(id) {
    return request.dataDel(`/course/module?id=${id}`);
}

export function fetchCourseModuleArticles(params) {
    return request.get('/course/module/article', params);
}

export function createCourseModuleArticle(params) {
    return request.post('/course/module/article/create', params);
}

export function updateCourseModuleArticle(params) {
    return request.patch('/course/module/article/edit', params);
}

export function deleteCourseModuleArticle(id) {
    return request.dataDel(`/course/module/article?id=${id}`);
}

export function fetchCourseModuleCategories(params) {
    return request.get('/course/module/category', params);
}

export function createCourseModuleCategory(params) {
    return request.post('/course/module/category/create', params);
}

export function updateCourseModuleCategory(params) {
    return request.patch('/course/module/category/edit', params);
}

export function deleteCourseModuleCategory(id) {
    return request.dataDel(`/course/module/category?id=${id}`);
}

export function fetchCourseModuleCourses(params) {
    return request.get('/course/module/course', params);
}

export function createCourseModuleCourse(params) {
    return request.post('/course/module/course/create', params);
}

export function updateCourseModuleCourse(params) {
    return request.patch('/course/module/course/edit', params);
}

export function deleteCourseModuleCourse(id) {
    return request.dataDel(`/course/module/course?id=${id}`);
}

export function fetchCourseModuleWikis(params) {
    return request.get('/course/module/wiki', params);
}

export function createCourseModuleWiki(params) {
    return request.post('/course/module/wiki/create', params);
}

export function updateCourseModuleWiki(params) {
    return request.patch('/course/module/wiki/edit', params);
}

export function deleteCourseModuleWiki(id) {
    return request.dataDel(`/course/module/wiki?id=${id}`);
}
