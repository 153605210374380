import { Modal, Flex, Select, Input, Space, Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { createPMRecommend, fetchPMProducts, updatePMRecommend } from "../../api/privatemarket";
import { useDebouncedCallback } from "use-debounce";

const RecommendForYouModalView = ({ oldData, open, onClose, onUpdate }) => {
    const [products, setProducts] = useState([])
    const [params, setParams] = useState({
        productId: null,
        weight: null
    });
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        if(oldData) {
            setProducts([
                {
                    label: oldData.product.name,
                    value: oldData.product.id,
                    key: oldData.product.id,
                }
            ]);
            setParams({
                productId: oldData.product.id,
                weight: oldData.weight
            });
        } else {
            setProducts([]);
            setParams({productId: null, weight: null})
            fetchProducts();
        }
    }, [oldData])

    const onFieldChange = (key, value) => {
        let newData = { ...params };
        newData[key] = value;
        setParams(newData);
    }

    const toSubmit = () => {
        setLoading(true);
        if (oldData) {
            updatePMRecommend({ ...oldData, ...params }).then(res => {
                if (res) {
                    onUpdate();
                    onClose();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createPMRecommend({ ...params }).then(res => {
                if (res) {
                    onUpdate();
                    onClose();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const fetchProducts = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'name': keyword,
            }
            setSearchLoading(true);
            fetchPMProducts(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        label: item.name,
                        value: item.id,
                        key: item.id,
                    }));
                    setProducts(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    return (
        <Modal
            width={700}
            open={open}
            title='Private Market Recommend-For-You Setting'
            onCancel={onClose}
            footer={
                <Space>
                    <Button type="primary" loading={loading} onClick={toSubmit}>Submit</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </Space>
            }>
            <div style={{ marginTop: 32 }} />
            <Flex align='center'>
                <div style={{ width: 120, textAlign: 'right', marginRight: 10 }}>Product:</div>
                <Select
                    size="large"
                    style={{ width: 400 }}
                    options={products}
                    value={params.productId}
                    placeholder='select product'
                    onChange={value => onFieldChange('productId', value)}
                    defaultActiveFirstOption={false}
                    suffixIcon={null}
                    filterOption={false}
                    onSearch={fetchProducts}
                    notFoundContent={searchLoading ? <Spin /> : null}
                    allowClear
                    showSearch
                />
            </Flex>
            <div style={{ marginTop: 12 }} />
            <Flex align='center'>
                <div style={{ width: 120, textAlign: 'right', marginRight: 10 }}>Weight:</div>
                <Input
                    size="large"
                    style={{ width: 400 }}
                    type="number"
                    value={params.weight}
                    placeholder="weight"
                    onChange={evt => onFieldChange('weight', evt.target.value)}
                    allowClear />
            </Flex>
            <div style={{ marginTop: 20 }} />
        </Modal>
    )
}

export default RecommendForYouModalView;