import React, { useEffect, useMemo, useState } from "react";
import {
    Route,
    HashRouter,
    Routes,
    useLocation,
    Navigate,
    useOutlet,
} from "react-router-dom";
import {
    AppstoreOutlined,
    GlobalOutlined,
    CarryOutOutlined,
    BookOutlined,
    InstagramOutlined,
    HomeOutlined,
    CommentOutlined,
    UserSwitchOutlined,
    QuestionCircleOutlined,
    HighlightOutlined,
    TeamOutlined,
    UsergroupAddOutlined,
    TagsOutlined,
    DashboardOutlined,
    ControlOutlined,
    IssuesCloseOutlined,
    MessageOutlined,
    StarFilled,
    ProductOutlined,
    TrademarkCircleOutlined,
    SolutionOutlined,
    SnippetsOutlined,
    FlagOutlined,
    ReconciliationOutlined,
    HolderOutlined,
    ProfileOutlined,
    ReadOutlined,
    FileTextOutlined,
    WalletOutlined,
    DollarOutlined,
    BarsOutlined,
    VideoCameraOutlined,
    TagOutlined,
    ClusterOutlined,
    SettingOutlined,
    VideoCameraAddOutlined,
    LoadingOutlined,
    IdcardOutlined,
    LineChartOutlined,
    BugOutlined,
    QuestionOutlined,
    AuditOutlined,
    RiseOutlined,
    BranchesOutlined,
    OrderedListOutlined,
    CompassOutlined,
    GiftOutlined,
    ForkOutlined,
    GroupOutlined,
    RobotOutlined,
} from "@ant-design/icons";
import LoginPanel from "../screens/login";
import MainLayout from "../layout/mainlayout";
import { getCookie } from "../utils/cookie";
import KeepAlive from "keepalive-for-react";
import { Spin, Flex } from "antd";
import { fetchAdminInfo } from "../api/admin";
import NotAccessScreen from "../screens/login/not_access";
import { MainLayoutUrl } from "./router_path";

export const UserInfoProviderContext = React.createContext(null);

function getItem(label, key, icon, children, type) {
    let title = label;
    return {
        key,
        icon,
        children,
        label,
        title,
        type,
    };
}

export const menuItems = [
    getItem('Dashboard', 'dashboard', <DashboardOutlined />),
    getItem('Banners', 'banner', <AppstoreOutlined />),
    getItem('Homepage', 'homepage', <HomeOutlined />, [
        getItem('Hot Article', 'hotarticle', <SnippetsOutlined />),
        getItem('Hot Topic', 'hottag', <FlagOutlined />),
    ]),
    getItem('Discover', 'discover', <InstagramOutlined />, [
        getItem('Videos', 'videos', <VideoCameraOutlined />),
        getItem('Hot Bounty', 'hotbounty', <TagOutlined />),
        getItem('Hot Post', 'hotpost', <FileTextOutlined />),
        getItem('Hot Video', 'hotvideo', <VideoCameraAddOutlined />),
    ]),
    getItem('Daily', 'daily', <CarryOutOutlined />, [
        getItem('WiKi', 'knowledge', <HighlightOutlined />),
        getItem('Q&A', 'question', <QuestionCircleOutlined />),
    ]),
    getItem('Learning', 'learning', <BookOutlined />, [
        getItem('Articles', 'learningarticles', <FileTextOutlined />),
        getItem('Courses', 'learningcourses', <BarsOutlined />),
        getItem('Course Category', 'coursecatalogs', <ForkOutlined />),
        getItem('Learning Center', 'learningcenter', <GroupOutlined />),
    ]),
    getItem('AI', 'ai', <RobotOutlined />, [
        getItem('Questions', 'aiquestions', <QuestionOutlined />),
    ]),
    getItem('Community', 'community', <CommentOutlined />, [
        getItem('Topic Tags', 'topictags', <HolderOutlined />),
        getItem('Content Tags', 'contenttags', <ProfileOutlined />),
        getItem('Approval', 'approvals', <AuditOutlined />, [
            getItem('Posts', 'pendingposts'),
            getItem('Articles', 'pendingarticles'),
            getItem('Comments', 'pendingcomments'),
        ]),
        getItem('Posts', 'post', <FileTextOutlined />, [
            getItem('Admin Posts', 'adminpost'),
            getItem('User Posts', 'userpost'),
        ]),
        getItem('Articles', 'article', <ReadOutlined />, [
            getItem('Admin Articles', 'adminarticle'),
            getItem('User Articles', 'userarticle'),
        ]),
        getItem('Comments', 'comment', <MessageOutlined />),
        getItem('Bounty Topic', 'bountytopic', <TagsOutlined />),
        getItem('Report Case', 'report', <IssuesCloseOutlined />),
        getItem('Crawled Posts', 'crawled', <BugOutlined />),
    ]),
    getItem('Private Market', 'privatemarket', <RiseOutlined />, [
        getItem('Asset Class', 'assetclass', <BranchesOutlined />),
        getItem('Products', 'privateproducts', <ProductOutlined />),
        getItem('Top Picks', 'toppicks', <OrderedListOutlined />),
        getItem('Recommended', 'recommendedforyou', <GiftOutlined />),
        getItem('Insights Cover', 'insightscover', <CompassOutlined />),
    ]),
    getItem('Users', 'users', <TeamOutlined />, [
        getItem('All Users', 'alluser', <UsergroupAddOutlined />),
        getItem('Recommended', 'recommenduser', <UserSwitchOutlined />),
        getItem('Whitelist Users', 'whitelistuser', <SolutionOutlined />),
        getItem('Virtual Users', 'virtualuser', <IdcardOutlined />),
        getItem('User Tags', 'ulabels', <TagsOutlined />),
        getItem('Profiling Quiz', 'profilingquiz', <QuestionOutlined />),
    ]),
    getItem('Task Center', 'points', <StarFilled />, [
        getItem('Point Overview', 'pointdata', <LineChartOutlined />),
        getItem('Products', 'products', <ProductOutlined />),
        getItem('Point Balance', 'balance', <TrademarkCircleOutlined />),
        getItem('Point Records', 'pointrecords', <ReconciliationOutlined />),
        getItem('Point Setting', 'pointsetting', <SettingOutlined />),
    ]),
    getItem('Crypto', 'crypto', <DollarOutlined />, [
        getItem('Wallet', 'wallet', <WalletOutlined />),
        getItem('Transactions', 'transactions', <BarsOutlined />),
    ]),
    getItem('Platform News', 'news', <GlobalOutlined />),
    getItem('System', 'system', <ControlOutlined />, [
        getItem('Admins', 'admins', <UsergroupAddOutlined />),
        getItem('Roles', 'roles', <ClusterOutlined />),
        getItem('Config Setting', 'configsetting', <SettingOutlined />),
    ]),
];

export const getRoutes = () => {
    let routers = Object.keys(MainLayoutUrl).map(key1 => {
        if (Object.keys(MainLayoutUrl[key1]).includes('index')) {
            return Object.keys(MainLayoutUrl[key1]).map(key2 => {
                return {
                    path: MainLayoutUrl[key1][key2].path,
                    key: MainLayoutUrl[key1][key2].key ? MainLayoutUrl[key1][key2].key : MainLayoutUrl[key1][key2].selectedKey,
                    selectedKey: MainLayoutUrl[key1][key2].selectedKey,
                    exact: true,
                    element: MainLayoutUrl[key1][key2].element,
                    needCache: MainLayoutUrl[key1][key2].needCache ? MainLayoutUrl[key1][key2].needCache : false,
                }
            })
        }
        return Object.keys(MainLayoutUrl[key1]).map(key2 => {
            return Object.keys(MainLayoutUrl[key1][key2]).map(key3 => {
                return {
                    path: MainLayoutUrl[key1][key2][key3].path,
                    key: MainLayoutUrl[key1][key2][key3].key ? MainLayoutUrl[key1][key2][key3].key : MainLayoutUrl[key1][key2][key3].selectedKey,
                    selectedKey: MainLayoutUrl[key1][key2][key3].selectedKey,
                    exact: true,
                    element: MainLayoutUrl[key1][key2][key3].element,
                    needCache: MainLayoutUrl[key1][key2][key3].needCache ? MainLayoutUrl[key1][key2][key3].needCache : false,
                }
            });
        }).reduce((x, y) => [...(x ? x : []), ...y]);
    }).reduce((x, y) => [...(x ? x : []), ...y]);
    return routers;
}

const getMenus = (permissionMenus, checkedMenus) => {
    let data = checkedMenus.filter(item => {
        if (permissionMenus.includes(item.key)) return true;
        if (item.children && item.children.length > 0) {
            return Array.from(item.children).some(ch1 => {
                if (permissionMenus.includes(ch1.key)) return true;
                if (ch1.children && ch1.children.length > 0) {
                    return Array.from(ch1.children).some(ch2 => permissionMenus.includes(ch2.key));
                }
                return false;
            })
        }
        return false;
    }).map(item => {
        if (item.children && item.children.length > 0) {
            return { ...item, children: getMenus(permissionMenus, item.children) }
        }
        return item;
    })
    return data;
}

const SystemRouters = () => {
    const [loaded, setLoaded] = useState(false);
    const [menusData, setMenusData] = useState([]);
    const [routesData, setRoutesData] = useState([]);
    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        // if (window.localStorage.getItem("_cachedGo")) {
        if (getCookie('user-token')) {
            fetchAdminInfo().then(res => {
                if (res) {
                    setUserInfo(res);
                    let menus = res?.permission?.menus.filter(item => !item.includes('/'));
                    let allRoutes = getRoutes();
                    if (menus?.includes('*')) {
                        setMenusData(menuItems);
                        setRoutesData(allRoutes)
                    } else {
                        let data = getMenus(menus, menuItems);
                        setMenusData(data);
                        setRoutesData(allRoutes.filter(item => {
                            return menus.includes(item.selectedKey)
                        }));
                    }
                    setLoaded(true);
                }
            })
        } else {
            setLoaded(true);
        }
    }, [])

    if (!loaded) {
        return (
            <Flex justify="center" align="center" style={{ height: '60vh' }}>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} />} />
            </Flex>
        )
    }

    let firstMenuRoute = routesData?.find(item => !item.path?.includes(':'))?.path;
    // let notAccess = window.localStorage.getItem("_cachedGo") && !firstMenuRoute ? true : false;
    let notAccess = getCookie('user-token') && !firstMenuRoute ? true : false;

    return (
        <UserInfoProviderContext.Provider value={userInfo}>
            <HashRouter>
                <Routes>
                    <Route
                        path="/login"
                        exact={true}
                        element={
                            <LoginRoute>
                                <LoginPanel />
                            </LoginRoute>
                        }
                    />
                    <Route
                        path="/notaccess"
                        exact={true}
                        element={<NotAccessScreen />}
                    />
                    <Route path='/' element={<MainLayout menus={menusData} accountName={userInfo?.username} />}>
                        <Route path='/' element={<BasicLayoutWithCache />}>
                            {
                                routesData.map((route) => {
                                    return (
                                        <Route
                                            key={route.key}
                                            path={route.path}
                                            exact={route.exact}
                                            element={<PrivateRoute>{route.element}</PrivateRoute>}
                                        />
                                    )
                                })
                            }
                        </Route>
                    </Route>
                    <Route
                        path="*"
                        element={<Navigate to={firstMenuRoute ? firstMenuRoute : (notAccess ? '/notaccess' : '/login')} replace={true} />}
                    />
                </Routes>
            </HashRouter>
        </UserInfoProviderContext.Provider>
    );
};

const LoginRoute = (props) => {
    const { children } = props;
    // const isLoggedIn = window.localStorage.getItem("_cachedGo") ? true : false;
    const isLoggedIn = getCookie("user-token") ? true : false;
    if (!isLoggedIn) {
        return <>{children}</>;
    }
    return <Navigate replace={true} to="/" />;
};

const PrivateRoute = (props) => {
    const { children } = props;
    // const isLoggedIn = window.localStorage.getItem("_cachedGo") ? true : false;
    const isLoggedIn = getCookie("user-token") ? true : false;
    const location = useLocation();
    if (isLoggedIn) {
        return <>{children}</>;
    }
    return (
        <Navigate
            replace={true}
            to="/login"
            state={{ from: `${location.pathname}${location.search}` }}
        />
    );
};

const BasicLayoutWithCache = () => {
    const outlet = useOutlet();
    const location = useLocation();

    const currRouter = useMemo(() => {
        let _router = getRoutes().find(item => {
            if (location.pathname.indexOf('/') !== location.pathname.lastIndexOf('/') && item.path.indexOf('/') !== item.path.lastIndexOf('/')) {
                return location.pathname.split('/')[1] === item.path.split('/')[1]
            }
            return item.path === location.pathname;
        });
        return _router
    }, [location]);

    if (!currRouter) {
        return <Navigate to={MainLayoutUrl.dashboard.index.path} replace={true} />;
    }

    return <div>
        <KeepAlive activeName={currRouter?.key} max={10} strategy={'LRU'} cache={currRouter?.needCache ?? false}>
            {outlet}
        </KeepAlive>
    </div>
}

export default SystemRouters;
