import { Descriptions, Input, Select, Tabs, Button, message, Flex } from "antd";
import { useMemo, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { createCourseModule, updateCourseModule } from "../../api/learning";
import CourseArticleTabView from "../../components/learning/course_article_tab_view";
import CourseModuleCategoryView from "../../components/learning/module_category_view";
import CourseModuleWikiView from "../../components/learning/module_wiki_view";
import CourseModuleArticleView from "../../components/learning/module_article_view";
import CourseModuleCourseView from "../../components/learning/module_course_view";

export const CourseModuleTypeOptions = [
    { key: '1', value: 'category', label: 'Category', tabName: 'Module Category Config' },
    { key: '2', value: 'course', label: 'Course', tabName: 'Module Course Config' },
    { key: '3', value: 'article', label: 'Article', tabName: 'Module Article Config' },
    { key: '4', value: 'wiki', label: 'Wiki', tabName: 'Module Wiki Config' },
];

const LearningCenterConfigScreen = () => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const defaultData = (location.state && location.state.detail ? JSON.parse(location.state.detail) : {})
    const [data, setData] = useState(defaultData);
    const [loading, setLoading] = useState(false);

    const onFormChange = (key, value) => {
        let updated = { ...data };
        updated[key] = value;
        setData(updated);
    }

    const tabItems = () => {
        if(id === '0') return [
            {
                label: 'Course Module Info',
                key: 1,
                children: courseInfoView(),
            }
        ]
        let tabName = CourseModuleTypeOptions.find(item => item.value === data.moduleType)?.tabName;
        return [
            {
                label: 'Course Module Info',
                key: 1,
                children: courseInfoView(),
            },
            {
                label: tabName,
                key: 2,
                children: <div>
                    {data.moduleType === 'category' && <CourseModuleCategoryView moduleId={data.id} />}
                    {data.moduleType === 'wiki' && <CourseModuleWikiView moduleId={data.id} />}
                    {data.moduleType === 'article' && <CourseModuleArticleView moduleId={data.id} />}
                    {data.moduleType === 'course' && <CourseModuleCourseView moduleId={data.id} />}
                </div>,
                disabled: id === '0' ? true : false
            }
        ]
    }

    const onSubmit = () => {
        setLoading(true);
        let params = {
            moduleName: data.moduleName,
            moduleType: data.moduleType,
            name: data.name,
            description: data.description,
            status: parseInt(data.status),
            weight: parseInt(data.weight)
        };
        if(id === '0') {
            createCourseModule(params).then(res => {
                if(res) {
                    message.success('Course module created!');
                    onCancel();
                }
            }).finally(() => {
                setLoading(false)
            })
        } else {
            updateCourseModule({...params, id}).then(res => {
                if(res) {
                    message.success('Course module updated!');
                    onCancel();
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const onCancel = () => {
        navigateTo(-1);
    }

    const courseInfoView = () => {
        return (
            <div>
                <Descriptions size='large' column={2} labelStyle={{ width: 250, textAlign: 'center' }} bordered>
                    <Descriptions.Item label='Module Name' span={2}>
                        <Input value={data.moduleName} placeholder="module name" onChange={evt => onFormChange('moduleName', evt.target.value)} allowClear />
                    </Descriptions.Item>
                    <Descriptions.Item label='Display Name' span={2}>
                        <Input value={data.name} placeholder="display name" onChange={evt => onFormChange('name', evt.target.value)} allowClear />
                    </Descriptions.Item>
                    <Descriptions.Item label='Module Type' span={2}>
                        <Select
                            disabled={id !== '0'}
                            value={data.moduleType}
                            style={{ width: '100%' }}
                            placeholder="module type"
                            onChange={value => onFormChange('moduleType', value)}
                            options={CourseModuleTypeOptions}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Weight' span={1}>
                        <Input value={data.weight} type="number" placeholder="weight" onChange={evt => onFormChange('weight', evt.target.value)} allowClear />
                    </Descriptions.Item>
                    <Descriptions.Item label='Status' span={1}>
                        <Select
                            value={data?.status || data?.status === 0 ?  String(data?.status) : null}
                            style={{ width: '100%' }}
                            placeholder="module status"
                            onChange={value => onFormChange('status', value)}
                            options={[
                                {label: 'Active', value: '0', key: 1},
                                {label: 'Inactive', value: '1', key: 2}
                            ]}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Description' span={2}>
                        <Input.TextArea value={data.description} placeholder="module description...." rows={6} onChange={evt => onFormChange('description', evt.target.value)} allowClear />
                    </Descriptions.Item>
                </Descriptions>

                <Flex justify="center" style={{marginTop: 24}}>
                    <Button loading={loading} type="primary" style={{ width: 200, height: 44 }} onClick={onSubmit}>Submit</Button>
                    <div style={{ width: 24 }} />
                    <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={onCancel}>Cancel</Button>
                </Flex>
            </div>
        )
    }

    return (
        <div style={{ padding: '20px' }}>
            <div className="title">{id ? (id === '0' ? 'Create New Module' : 'Edit Module') : ''}</div>
            <div style={{ height: 12 }} />
            <Tabs items={tabItems()} />
        </div>
    )
}

export default LearningCenterConfigScreen;