import { Table, Modal, Flex, Button, message } from "antd";
import { useState, useEffect } from 'react';
import { fetchTagRewards, sendSystemNotification } from "../../api/community";
import dayjs from "dayjs";
import { Typography } from "antd";
import UserInfoView from "./user_info_view";
import RewardEditModalView from "./reward_edit_modal_view";
import { capitalizeFirstLetter } from "../../utils/comm";
import { useNavigate } from "react-router-dom";
import { MainLayoutUrl } from "../../router/router_path";

const RewardUsersModalView = ({ open, tag, onClose }) => {
    const navigateTo = useNavigate();
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => (index + 1),
        },
        {
            title: 'User Info',
            dataIndex: 'user',
            key: 'user',
            render: (text, record) => {
                return <UserInfoView user={text} showWallet={true} />
            },
        },
        {
            title: 'User Id',
            dataIndex: 'user',
            key: 'user',
            render: (text, _) => {
                return <Typography.Text style={{ maxWidth: 100, fontSize: 12 }} ellipsis copyable>{text.userId}</Typography.Text>;
            }
        },
        {
            title: 'Topic',
            dataIndex: 'tag',
            key: 'tag',
            render: (text, _) => {
                return <div style={{ maxWidth: 200 }}>{`#${text.name}`}</div>;
            },
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'TxID',
            dataIndex: 'txId',
            key: 'txId',
            width: 200,
            render: (text, record) => {
                if (!text) return '-';
                return <Typography.Paragraph ellipsis={{ rows: 2 }}>
                    <a onClick={() => toTransaction(record)} style={{ textDecoration: 'underline' }}>{text}</a>
                </Typography.Paragraph>;
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (_, record) => {
                return (
                    <Typography.Text type="success">
                        <b>{record.amount + ' ' + record.symbol}
                        {
                            record.chain && <span>&nbsp;[{capitalizeFirstLetter(record.chain)}]</span>
                        }
                        </b>
                    </Typography.Text>
                )
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        {
                            (!record.user.addressList || record.user.addressList.length <= 0 || !record.user.phoneNumber) && <Button type='link' onClick={() => { remindUser(record) }}>Remind User</Button>
                        }
                        {
                            !record.txId && <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        }
                    </Button.Group>
                )
            },
        }
    ];

    const [tabData, setTabData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currUser, setCurrUser] = useState(null);
    const [openEditModal, setOpenEditModal] = useState(false);

    useEffect(() => {
        if (tag && open) {
            fetchData();
        }
    }, [tag, open]);

    const toTransaction = (record) => {
        onClose();
        navigateTo(`${MainLayoutUrl.crypto.transactions.index.path}?tx=${record.txId}`)
    }

    const fetchData = () => {
        setLoading(true);
        let params = {
            'page.disable': true,
            'tag': tag,
        };
        fetchTagRewards(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const remindUser = (record) => {
        message.loading('reminding user...')
        let { user } = record;
        let params = {
            userId: user.userId,
            type: 'CREATE_ADDRESS',
            title: `You won!`,
            body: `Congrats! You have won ${record.amount} ${record.symbol} bounty for #${record.tag.name}. Activate your wallet now to claim your rewards!`,
            // data: {
            //     id: record.postId
            // }
        }
        sendSystemNotification(params).then(res => {
            if(res) {
                message.destroy();
                message.success('we have reminded user!')
            }
        }).catch(err => {
            message.destroy();
            message.error(String(err));
        });
    }

    const editHandle = (record) => {
        setCurrUser(record);
        setOpenEditModal(true);
    }

    if (!tag) return <div />;

    return (
        <Modal
            maskClosable
            open={open}
            footer={null}
            width={1200}
            onCancel={onClose}
            title='Reward Users'>
            <div className='main-wrapper'>
                <div className='cnt-item' style={{ marginTop: 10 }}>
                    <Table
                        scroll={{ x: true }}
                        loading={loading}
                        rowKey={(record) => record.id}
                        rowClassName={'table-row'}
                        size='small'
                        dataSource={tabData}
                        columns={tabCols} />
                </div>

                <RewardEditModalView
                    users={currUser ? [currUser] : null}
                    open={openEditModal}
                    isEdit={true}
                    onClose={() => setOpenEditModal(false)}
                    onUpdate={() => fetchData()} />
            </div>
        </Modal>
    )
}

export default RewardUsersModalView;