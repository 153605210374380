import { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Pagination, Tag, Flex, Image } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { message } from 'antd';
import { deleteCourseModuleArticle, fetchCourseModuleArticles, updateCourseModuleArticle } from '../../api/learning';
import WikiEditModalView from './wiki_edit_model_view';
import ArticleEditModalView from './article_edit_modal_view';

const CourseModuleArticleView = ({ moduleId }) => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currArticle, setCurrArticle] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'index',
            key: 'index',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1)
        },
        {
            title: 'Article Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => {
                const { post } = record;
                if (post) return <b>{post.title}</b>
            },
        },
        {
            title: 'Cover',
            dataIndex: 'cover',
            key: 'cover',
            render: (text, _) => {
                if (text) {
                    return <Image src={text} style={{ maxWidth: 120, maxHeight: 60, objectFit: 'contain' }} />
                }
                return '';
            },
        },
        {
            title: 'Site',
            dataIndex: 'site',
            key: 'site',
            render: (text, _) => {
                return <b>{text}</b>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (String(text) === '0') return <Tag color='success'>Active</Tag>;
                return <Tag color='warning'>Inactive</Tag>
            },
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Button type='link' onClick={() => { activeHandle(record) }}>{String(record.status) === '0' ? 'Inactive' : 'Active'}</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this article?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getModuleArticles(pageNum, pageSize);
    }, [])

    const getModuleArticles = (pNum, pSize) => {
        setLoading(true);

        let params = {
            'page.num': pNum,
            'page.size': pSize,
            moduleId
        }
        fetchCourseModuleArticles(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getModuleArticles(page, size);
    }

    const activeHandle = (record) => {
        setLoading(true);
        updateCourseModuleArticle({ ...record, status: record.status === 1 ? 0 : 1 }).then(res => {
            if (res) {
                getModuleArticles(pageNum, pageSize);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const editHandle = (record) => {
        setCurrArticle(record);
        setModalOpen(true);
    }

    const addArticle = () => {
        setCurrArticle(null);
        setModalOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteCourseModuleArticle(record.id).then(res => {
            if (res) {
                message.success('deleted!');
                getModuleArticles(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='main-wrapper'>
            <Flex className='top-part cnt-item' justify='flex-end'>
                <Button type='primary' icon={<PlusOutlined />} onClick={addArticle}>Add New Article</Button>
            </Flex>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <ArticleEditModalView
                moduleId={moduleId}
                open={modalOpen}
                defaultArticle={currArticle}
                onClose={() => setModalOpen(false)}
                onUpdate={() => {
                    getModuleArticles(pageNum, pageSize);
                }}
            />
        </div>
    )
}

export default CourseModuleArticleView;