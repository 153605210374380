import { Input, Button, message, Select, Form, Row, Col, Drawer, Space, Avatar, Spin, Typography, Radio } from 'antd';
import { createTopicTopPost, fetchPosts } from '../../api/community';
import { useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { conversionUtcDate } from '../../utils/comm';

const TopicTagPostEditDrawer = ({ open, defaultPost, onClose, onUpdate, tagId }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [form] = Form.useForm();
    const [posts, setPosts] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        setData(defaultPost);

        form.setFieldValue('search', null);
        if (defaultPost) {
            form.setFieldValue('postId', defaultPost.post.id)
            form.setFieldValue('title', defaultPost.post.title)
            form.setFieldValue('weight', defaultPost.weight);
        } else {
            resetFields();
        }
        getPosts('');
    }, [defaultPost]);

    const resetFields = () => {
        form.setFieldValue('search', null);
        form.setFieldValue('postId', '')
        form.setFieldValue('title', '')
        form.setFieldValue('weight', '');
    }

    const onSubmit = () => {
        let params = {
            tagId,
            postId: form.getFieldValue('postId'),
            weight: form.getFieldValue('weight') ? form.getFieldValue('weight') : 0,
        };
        delete params['post'];
        if (!params.postId) {
            message.warning('Please choose a post');
            return;
        }
        setLoading(true);
        createTopicTopPost(params).then(res => {
            resetFields();

            onClose();
            onUpdate();
        }).finally(() => {
            setLoading(false);
        })
    }

    const onSelectChange = (value) => {
        form.setFieldValue('postId', value);
        let post = posts.find(item => item.value === value);
        if (post) {
            form.setFieldValue('title', post.label);
        }
    }

    const handleSearch = (value) => {
        getPosts(value);
    }

    const getPosts = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
                'status': 1,
                'tagId': tagId
            }
            setSearchLoading(true);
            fetchPosts(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => {
                        let label = item.title;
                        if(!label) {
                            let div = document.getElementById('contentHolder');
                            div.innerHTML = '';
                            div.innerHTML = item.content;
                            label = div.innerText;
                        }
                        return {
                            avatar: (item.user.picture ? item.user.picture : item.user.avatar),
                            createdAt: conversionUtcDate(item.createdAt, null, true),
                            label: label,
                            value: item.id,
                        }
                    });
                    setPosts(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultPost ? 'Edit top post' : 'Add top post'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='search' label='Search Post/Article'>
                                <Select
                                    style={{ width: '100%' }}
                                    size='large'
                                    placeholder='you can input keywords to search article'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={posts}
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                {
                                                    option.data.avatar ?
                                                        <Avatar size={32} src={option.data.avatar} />
                                                        :
                                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                }
                                                <div style={{ flex: 1 }}>
                                                    <b style={{ wordBreak: 'break-word' }}>{option.data.label ? option.data.label : 'anonymous'}</b>
                                                    <div>
                                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                            {option.data.createdAt}
                                                        </Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    onChange={onSelectChange}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ height: 36, width: 200 }} />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='postId' label='Article ID'>
                                <Input placeholder="please input article id" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='title' label='Article Title'>
                                <Input.TextArea autoSize disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='weight' label='Weight'>
                                <Input placeholder="recommend weight" type='number' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

            <div style={{display: 'none'}} id='contentHolder' />
        </div>
    )
}

export default TopicTagPostEditDrawer;