import { Input, Button, message, Select, Form, Row, Col, Drawer, Space, Avatar, Spin, Typography, Radio } from 'antd';
import { fetchPosts } from '../../api/community';
import { useEffect, useState, useMemo } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';
import { createHomePageHotArticle, updateHomePageHotArticle } from '../../api/homepage';
import { conversionUtcDate } from '../../utils/comm';
import { CloudStorageImageUploader } from '../upload';
import { customUploaderBtn } from '../comm/custom_uploader_btn';

const HotArticleEditView = ({ open, defaultArticle, onClose, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [form] = Form.useForm();
    const [posts, setPosts] = useState([]);
    const [data, setData] = useState({});

    useEffect(() => {
        setData(defaultArticle);

        form.setFieldValue('search', null);
        if (defaultArticle) {
            form.setFieldValue('postId', defaultArticle.post.id)
            form.setFieldValue('title', defaultArticle.post.title)
            form.setFieldValue('weight', defaultArticle.weight);
            form.setFieldValue('status', String(defaultArticle.status).toLowerCase() === 'active' ? 0 : 1);
        } else {
            resetFields();
        }
        getPosts('');
    }, [defaultArticle]);

    const resetFields = () => {
        form.setFieldValue('search', null);
        form.setFieldValue('postId', '')
        form.setFieldValue('title', '')
        form.setFieldValue('weight', '');
        form.setFieldValue('status', 0);
    }

    const onSubmit = () => {
        let params = {
            ...data,
            postId: form.getFieldValue('postId'),
            weight: form.getFieldValue('weight'),
            status: form.getFieldValue('status') ? parseInt(form.getFieldValue('status')) : 0,
            cover: (data?.cover ?? '')
        };
        delete params['post'];
        if (!params.postId || !params.weight) {
            message.warning('Incomplete information');
            return;
        }
        setLoading(true);
        if (params.id) {
            updateHomePageHotArticle(params).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).catch(err => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createHomePageHotArticle(params).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).catch(err => {
                message.error(String(err));
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onSelectChange = (value) => {
        form.setFieldValue('postId', value);
        let post = posts.find(item => item.value === value);
        if (post) {
            form.setFieldValue('title', post.label);
        }
    }

    const handleSearch = (value) => {
        getPosts(value);
    }

    const getPosts = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
                'categoryId': '2',
                'status': 1,
            }
            setSearchLoading(true);
            fetchPosts(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        avatar: (item.user.picture ? item.user.picture : item.user.avatar),
                        createdAt: conversionUtcDate(item.createdAt, null, true),
                        label: item.title,
                        value: item.id,
                    }));
                    setPosts(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const onImageUpdate = (files) => {
        if (!files || files.length <= 0) {
            setData({ ...(data ?? {}), cover: '' })
        } else {
            let newData = { ...(data ?? {}) };
            newData['cover'] = files[0].remoteUrl;
            setData(newData);
        }
    }


    const uploadCoverProps = {
        name: 'article-cover-uploader',
        bucketPath: '/article-cover',
        defaultFileList: useMemo(() => {
            return data && data.cover ? [{
                name: (decodeURIComponent(data.cover).split('/').pop() ?? '').split('?')[0],
                url: data.cover,
                percent: 100,
            }] : []
        }, [data?.cover]),
        showUploadList: { showRemoveIcon: false },
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Cover'),
        onUpdate: onImageUpdate,
    }

    return (
        <div>
            <Drawer
                forceRender={true}
                title={defaultArticle ? 'Edit hot article' : 'Add hot article'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='search' label='Search Article'>
                                <Select
                                    style={{ width: '100%' }}
                                    size='large'
                                    placeholder='you can input keywords to search article'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={posts}
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                {
                                                    option.data.avatar ?
                                                        <Avatar size={32} src={option.data.avatar} />
                                                        :
                                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                }
                                                <div style={{ flex: 1 }}>
                                                    <b style={{ wordBreak: 'break-word' }}>{option.data.label ? option.data.label : 'anonymous'}</b>
                                                    <div>
                                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                            {option.data.createdAt}
                                                        </Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    onChange={onSelectChange}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ height: 36, width: 200 }} />

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='postId' label='Article ID'>
                                <Input placeholder="please input article id" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='title' label='Article Title'>
                                <Input.TextArea autoSize disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='cover' label='Cover'>
                                <CloudStorageImageUploader {...uploadCoverProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='weight' label='Weight'>
                                <Input placeholder="recommend weight" type='number' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='status' label='Status'>
                                <Radio.Group buttonStyle='solid' disabled={!data?.id || parseInt(data?.id) === 0}>
                                    <Radio.Button value={0}>Active</Radio.Button>
                                    <Radio.Button value={1}>Inactive</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </div>
    )
}

export default HotArticleEditView;