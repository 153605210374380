import { Descriptions, Form, Input, Select, Row, Col, Button, Space } from "antd";
import { useEffect, useState } from "react";
import { createPMProduct, fetchAssetClass, updatePMProduct } from "../../api/privatemarket";
import { useNavigate, useLocation } from "react-router-dom";
import { MainLayoutUrl } from "../../router/router_path";
import { useParams } from "react-router-dom";

const PMProductDetailScreen = () => {
    const [form] = Form.useForm();
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = useParams();
    useEffect(() => {
        getAssets();
        if (location.state && location.state.detail) {
            let old = JSON.parse(location.state.detail);
            form.setFieldsValue({...old, assetClassId: old.assetClass.id})
        }
    }, [])

    const getAssets = () => {
        fetchAssetClass({ 'page.disable': true }).then(res => {
            if (res.list) {
                setAssets(res.list.map(item => {
                    return {
                        key: item.id,
                        value: item.id,
                        label: item.name
                    }
                }))
            }
        })
    }

    const toSubmit = (values) => {
        setLoading(true);
        let params = {...values};
        if(id && parseInt(id) > 0) {
            params.id = id;
            updatePMProduct(params).then(res => {
                if(res) {
                    toCancel();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createPMProduct(params).then(res => {
                if(res) {
                    toCancel();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const toCancel = () => {
        navigate(MainLayoutUrl.privatemarket.privateproducts.index.path);
    }
    
    return (
        <Descriptions column={2} bordered layout="vertical" title='Private Market Product Info' style={{ margin: 20 }}>
            <Descriptions.Item span={2} label='Info'>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={toSubmit}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='assetClassId' label='Asset class' rules={[{ required: true, message: 'Please select asset class' }]}>
                                <Select options={assets} placeholder="Asset class" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='name' label='Product Name' rules={[{ required: true, message: 'Please input name' }]}>
                                <Input.TextArea showCount maxLength={1000} placeholder="name" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='code' label='Code' rules={[{ required: true, message: 'Please input code' }]}>
                                <Input placeholder="code" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='description' label='Description' rules={[{ required: true, message: 'Please input description' }]}>
                                <Input.TextArea placeholder="description" allowClear rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='info1' label='Info1 Name' rules={[{ required: true, message: 'Please input info1 name' }]}>
                                <Input placeholder="info name" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='infoNumber1' label='Info1 Number' rules={[{ required: true, message: 'Please input info1 number' }]}>
                                <Input placeholder="info number" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item name='info2' label='Info2 Name' rules={[{ required: true, message: 'Please input info2 name' }]}>
                                <Input placeholder="info name" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='infoNumber2' label='Info2 Number' rules={[{ required: true, message: 'Please input info2 number' }]}>
                                <Input placeholder="info number" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='investmentHighlights' label='Investment Highlights'>
                                <Input.TextArea placeholder="highlights" allowClear rows={8} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='subscriptionStatus' label='Subscription Status'>
                                <Input.TextArea placeholder="subscription status" allowClear rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label={null}>
                                <Space>
                                    <Button loading={loading} size="large" type="primary" htmlType="submit">Submit</Button>
                                    <Button size="large" onClick={toCancel}>Cancel</Button>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Descriptions.Item>
        </Descriptions>
    )
}

export default PMProductDetailScreen;