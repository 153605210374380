import request from "../utils/request";

export function fetchAICategories(params) {
    return request.get('/ai/category', params);
}

export function fetchAIQuestions(params) {
    return request.get('/ai/question', params);
}

export function createAIQuestion(params) {
    return request.post('/ai/question/create', params);
}

export function updateAIQuestion(params) {
    return request.patch('/ai/question/edit', params);
}

export function deleteAIQuestion(id) {
    return request.dataDel(`/ai/question?id=${id}`);
}