import { Button, message, Select, Form, Row, Col, Modal, Space, Avatar, Spin, Typography } from 'antd';
import { createWhitelistUser, fetchUserSearch } from '../../api/community';
import { useEffect, useState } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { useDebouncedCallback } from 'use-debounce';

const WhitelistUserModalView = ({ open, onClose, onUpdate }) => {
    const [loading, setLoading] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (open) {
            form.setFieldValue('userId', null)
            fetchUsers('');
        }
    }, [open]);

    const onSubmit = () => {
        let params = {
            userId: form.getFieldValue('userId'),
        };
        if (!params.userId) {
            message.warning('Please select user');
            return;
        }
        setLoading(true);
        createWhitelistUser(params).then(res => {
            onClose();
            onUpdate();
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleSearch = (value) => {
        fetchUsers(value);
    }

    const fetchUsers = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'keyword': keyword,
            }
            setSearchLoading(true);
            fetchUserSearch(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        avatar: (item.picture ? item.picture : item.avatar),
                        email: item.email,
                        label: item.name,
                        value: item.userId,
                    }));
                    setUsers(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    return (
        <div>
            <Modal
                title={'Add Whitelist User For Approval'}
                width={480}
                maskClosable
                onCancel={onClose}
                open={open}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
                styles={{body: {padding: 20}}}
            >
                <Form
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='userId' label='Select User'>
                                <Select
                                    placeholder='select user'
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    notFoundContent={searchLoading ? <Spin /> : null}
                                    options={users}
                                    size='large'
                                    optionRender={(option) => {
                                        return (
                                            <Space>
                                                {
                                                    option.data.avatar ?
                                                        <Avatar size={32} src={option.data.avatar} />
                                                        :
                                                        <Avatar size={32} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                                                }
                                                <div>
                                                    <b>{option.data.label ? option.data.label : 'anonymous'}</b>
                                                    <div>
                                                        <Typography.Text style={{ color: '#999', fontSize: 12 }}>{option.data.email ?? option.data.value}</Typography.Text>
                                                    </div>
                                                </div>
                                            </Space>
                                        )
                                    }}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default WhitelistUserModalView;