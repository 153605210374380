import { Input, Button, Select, Form, Row, Col, Space, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { createAIQuestion, updateAIQuestion } from '../../api/ai';

const AIQuestionEditModalView = ({ categories, defaultQuestion, onClose, onUpdate, open }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (defaultQuestion) {
            form.setFieldValue('categoryId', defaultQuestion.categoryId)
            form.setFieldValue('weight', defaultQuestion.weight)
            form.setFieldValue('content', defaultQuestion.content)
        } else {
            resetFields();
        }
    }, [open]);

    const resetFields = () => {
        form.setFieldValue('categoryId', null)
        form.setFieldValue('content', '')
        form.setFieldValue('weight', '')
    }

    const onSubmit = () => {
        let params = {
            categoryId: form.getFieldValue('categoryId'),
            content: form.getFieldValue('content'),
            weight: form.getFieldValue('weight')
        };
        setLoading(true);
        if (defaultQuestion?.id) {
            updateAIQuestion({ ...params, id: defaultQuestion.id }).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createAIQuestion(params).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const drawTitle = () => {
        if (defaultQuestion) {
            return 'Edit AI Question'
        }
        return 'Create AI Question'
    }

    return (
        <div>
            <Modal
                title={drawTitle()}
                width={480}
                mask
                onCancel={onClose}
                open={open}
                styles={{
                    body: {
                        paddingTop: 32,
                    },
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='categoryId' label='AI Question Category'>
                                <Select placeholder='question category' options={categories} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='content' label='Question Content'>
                                <Input placeholder='content' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='weight' label='Weight'>
                                <Input placeholder='weight' type='number' />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default AIQuestionEditModalView;