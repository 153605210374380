import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, Image, message, Popconfirm, Tag, Modal, Input, Space, Flex, DatePicker, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';

import { MainLayoutUrl } from '../../router/router_path';
import { fetchDiscovers, updateDiscover, deleteDiscover } from '../../api/discover';
import dayjs from 'dayjs';
import { VideoCategoryColors, VideoCategoryOptions } from './detail';
import { RichText } from '@chatui/core';
import { useDebouncedCallback } from 'use-debounce';
import DiscoverPreview from '../../components/discover/discover_preview';
import UserInfoView from '../../components/community/user_info_view';

const DiscoverScreen = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const tabCols = [
        {
            title: 'Created',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Author',
            dataIndex: 'user',
            key: 'user',
            render: (text, _) => {
                return <UserInfoView user={text} showWallet={false} />
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Category',
            dataIndex: 'videoType',
            key: 'videoType',
            render: (text, _) => {
                return <Tag color={VideoCategoryColors[text]}>{VideoCategoryOptions.find(item => item.value === text).label}</Tag>
            }
        },
        {
            title: 'Description',
            dataIndex: 'content',
            key: 'content',
            render: (text, _) => {
                return (
                    <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, maxWidth: 150 }}>
                        <div className="community-content" style={{ WebkitLineClamp: 1 }}>
                            <RichText content={text} />
                        </div>
                    </div>
                )
            }
        },
        {
            title: 'Recommend',
            dataIndex: 'discover',
            key: 'discover',
            render: (text, _) => {
                if (parseInt(text) <= 0) return '';
                return <b>{text}</b>
            }
        },
        {
            title: 'Topic',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, _) => {
                return <div style={{ maxWidth: 200 }}>{text.filter(item => item).map(item => String(item).indexOf('#') === 0 ? item : `#${item}`).join(', ')}</div>;
            }
        },
        {
            title: 'Cover',
            dataIndex: 'resources',
            key: 'resources',
            render: (resources, _) => {
                if (!resources[1]) return '';
                return (
                    <Image src={resources[1]} style={{ maxWidth: 120, maxHeight: 60, objectFit: 'contain' }} />
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editDiscover(record) }}>Edit</Button>
                        <Button type='link' onClick={() => { reviewDiscover(record) }}>Review</Button>
                        <Button type='link' onClick={() => { recommendHandle(record) }}>Recommend</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this discover?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currDiscover, setCurrDiscover] = useState();
    const [open, setOpen] = useState(false);
    const [searchParams, setSearchParams] = useState({
        keyword: '',
        videoType: '',
        gteCreatedAt: '',
        ltCreatedAt: '',
        order: '',
    });

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams)
    }, [location]);

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = { 'page.num': pNum, 'page.size': pSize, ...search };
        fetchDiscovers(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list.map(item => ({
                    ...item,
                    resources: item.resources.map(res => res.url),
                    tags: item.tags.map(res => res.name),
                })));
            }
        }).finally(() => setLoading(false));
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const createNewVideo = () => {
        navigate(MainLayoutUrl.discover.videos.item.path.replace(':id', '0'));
    }

    const editDiscover = (item) => {
        navigate(MainLayoutUrl.discover.videos.item.path.replace(':id', item.id), { state: { detail: JSON.stringify(item) } })
    }

    const recommendHandle = (record) => {
        let weight = String(record.discover);
        let modal = Modal.confirm({
            closable: false,
            title: 'Recommend the discover?',
            content: (
                <div>
                    <Input defaultValue={weight} type="number" prefix={'Recommend Weight: '} placeholder="weight" onChange={evt => {
                        weight = evt.target.value;
                        modal.update({
                            okButtonProps: {
                                disabled: !weight.trim(),
                            }
                        })
                    }} allowClear />
                </div>
            ),
            okText: 'Confirm',
            okButtonProps: {
                disabled: !weight.trim(),
            },
            cancelText: 'Cancel',
            onOk: async () => {
                let params = { ...record, discover: weight };
                await updateDiscover(params);
                fetchData(pageNum, pageSize, searchParams);
            }
        })
    }

    const deleteHandle = (item) => {
        setLoading(true);
        deleteDiscover(item.id).then(res => {
            setLoading(false);
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            setLoading(false);
            message.error(String(err));
        });
    }

    const reviewDiscover = (item) => {
        setCurrDiscover(item);
        setOpen(true);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.gteCreatedAt = `${value[0]}T00:00:00Z`;
                    newParams.ltCreatedAt = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.gteCreatedAt = '';
                    newParams.ltCreatedAt = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={createNewVideo}>Create New Video</Button>
                    <Space size={8} wrap style={{ justifyContent: 'flex-end' }}>
                        <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                        <Select
                            style={{ width: 160 }}
                            options={VideoCategoryOptions}
                            placeholder='Video Type'
                            onChange={value => onSearchChange('videoType', value)}
                            allowClear
                        />
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('keyword', evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <DiscoverPreview
                open={open}
                discover={currDiscover}
                onClose={() => setOpen(false)}
            />
        </div>
    )
}

export default DiscoverScreen;