import { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Pagination, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { createCourseCategory, deleteCourseCategory, fetchCourseCategories, updateCourseCategory } from '../../api/learning';

const CourseCategoriesScreen = () => {
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                let val = record.name;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, name: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, record) => {
                let val = record.weight;
                return <Typography.Text editable={
                    {
                        onChange: (value) => {
                            val = value;
                        },
                        onEnd: () => {
                            editHandle({ ...record, weight: val });
                        }
                    }
                }>{text}</Typography.Text>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <Button.Group size='small'>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this course category?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getCourseCategories(pageNum, pageSize);
    }, [])

    const getCourseCategories = (pNum, pSize) => {
        setLoading(true);

        let params = {
            'page.num': pNum,
            'page.size': pSize,
        }
        fetchCourseCategories(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getCourseCategories(page, size);
    }

    const editHandle = (params) => {
        setLoading(true);
        if (parseInt(params.id) > 0) {
            updateCourseCategory(params).then(res => {
                if (res) {
                    message.success('updated!');
                    getCourseCategories(pageNum, pageSize);
                }
            }).finally(() => setLoading(false));
        } else {
            let newParams = { ...params };
            delete newParams['id'];
            createCourseCategory(newParams).then(res => {
                if (res) {
                    message.success('created!');
                    getCourseCategories(pageNum, pageSize);
                }
            }).finally(() => setLoading(false));
        }
    }

    const addCategory = () => {
        if (tabData.findIndex(item => item.id === '-1') >= 0) return;
        setTabData([
            {
                id: '-1',
                name: '',
                weight: 0,
            }, ...tabData
        ]);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteCourseCategory(record.id).then(res => {
            if (res) {
                message.success('deleted!');
                getCourseCategories(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={addCategory}>Create Category</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default CourseCategoriesScreen;