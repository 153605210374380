import { Input, Button, Select, Form, Row, Col, Space, Radio, Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { createCourseModuleCategory, fetchCourseCategories, updateCourseModuleCategory } from '../../api/learning';
import { CloudStorageImageUploader } from '../upload';
import { customUploaderBtn } from '../comm/custom_uploader_btn';
const CategoryEditModalView = ({ moduleId, defaultCategory, onClose, onUpdate, open }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [icon, setIcon] = useState();

    useEffect(() => {
        fetchCourseCategories({ 'page.disable': true }).then(res => {
            if (res.list) {
                setCategories(res.list.map(item => {
                    return {
                        key: item.id,
                        value: item.id,
                        label: item.name,
                    }
                }))
            }
        })
    }, [])

    useEffect(() => {
        if (defaultCategory) {
            form.setFieldValue('categoryId', defaultCategory.categoryId)
            form.setFieldValue('status', defaultCategory.status)
            form.setFieldValue('site', defaultCategory.site)
            setIcon(defaultCategory.icon)
        } else {
            resetFields();
        }
    }, [open]);

    const resetFields = () => {
        form.setFieldValue('categoryId', null)
        form.setFieldValue('status', null)
        form.setFieldValue('site', '')
        setIcon(null)
    }

    const onSubmit = () => {
        let params = {
            moduleId,
            categoryId: form.getFieldValue('categoryId'),
            icon: icon,
            status: form.getFieldValue('status'),
            site: form.getFieldValue('site')
        };
        setLoading(true);
        if (defaultCategory?.id) {
            updateCourseModuleCategory({ ...params, id: defaultCategory.id }).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        } else {
            createCourseModuleCategory(params).then(res => {
                if (res) {
                    resetFields();

                    onClose();
                    onUpdate();
                }
            }).finally(() => {
                setLoading(false);
            })
        }
    }

    const onCoverImageUpdate = (files) => {
        if (files[0]) {
            setIcon(files[0].remoteUrl ? files[0].remoteUrl : files[0].url)
        } else {
            setIcon(null)
        }
    }

    const moduleCoverUploadProps = {
        defaultFileList: useMemo(() => {
            return icon ? [{
                name: (decodeURIComponent(icon).split('/').pop() ?? '').split('?')[0],
                url: icon,
                percent: 100,
            }] : [];
        }, [icon]),
        name: 'course-module-icon-uploader',
        bucketPath: '/course-module-icon',
        listType: 'picture-card',
        isMultiple: false,
        needItemRender: false,
        useCardPreview: true,
        showUploadList: true,
        buttonNode: customUploaderBtn('Upload Cover'),
        onUpdate: onCoverImageUpdate
    }

    const drawTitle = () => {
        if(defaultCategory) {
            return 'Edit module category'
        }
        return 'Add new module category'
    }

    return (
        <div>
            <Modal
                title={drawTitle()}
                width={480}
                mask
                onCancel={onClose}
                open={open}
                styles={{
                    body: {
                        paddingTop: 32,
                    },
                }}
                footer={
                    <Space>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={onSubmit} type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    form={form}
                    requiredMark={false} >
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='categoryId' label='Course Category'>
                                <Select placeholder='course category' options={categories} disabled={defaultCategory?.id} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='icon' label='Icon'>
                                <CloudStorageImageUploader {...moduleCoverUploadProps} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='site' label='Site'>
                                <Input placeholder='site' />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item name='status' label='Status'>
                                <Radio.Group buttonStyle='solid'>
                                    <Radio.Button value={0}>Active</Radio.Button>
                                    <Radio.Button value={1}>Inactive</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}

export default CategoryEditModalView;