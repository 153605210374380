import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from 'react';
import { BucketType, firebaseConfig } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { message, Modal, Select, Space, Typography, Spin, Button } from 'antd';
import { fetchAllTags } from '../../api/community';
import { conversionUtcDate, getParenthesesStr, uuid } from '../../utils/comm';
import { useDebouncedCallback } from 'use-debounce';
import { reAuthenticationForStorage } from '../../utils/request';


const RichTextEditor = ({ contentField, defaultHeight = 600, defaultContent, onUpdate, needEditTags = false }) => {

    const editorRef = useRef(null);
    const [topics, setTopics] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isTicker, setIsTicker] = useState(false);
    const [topicId, setTopicId] = useState(null);

    const getTopicTags = useDebouncedCallback(
        (keyword) => {
            let params = {
                'page.num': 1,
                'page.size': 10,
                'name': keyword,
                'level': 1,
                'isTopicTag': false,
                'status': 0,
                'isTickerTag': isTicker
            }
            setSearchLoading(true);
            fetchAllTags(params).then(res => {
                if (res.list) {
                    let tmp = res.list.map(item => ({
                        createdAt: conversionUtcDate(item.createdAt, null, true),
                        label: item.name,
                        value: item.id,
                        ticker: item.ticker
                    }));
                    setTopics(tmp);
                }
            }).finally(() => setSearchLoading(false))
        },
        300
    )

    const onSelectChange = (value) => {
        setTopicId(value);
    }

    const confirmInsertTag = () => {
        let tagInfo = topics.find(item => item.value === topicId)
        let tagName = tagInfo.label;
        let editSelection = editorRef.current.selection;
        if (editSelection) {
            let range = editSelection.getRng();
            let newNode = editorRef.current.getDoc().createElement('span');
            if (isTicker) {
                let shortTag = tagName;
                if (shortTag.includes('(') && shortTag.includes(')')) {
                    shortTag = getParenthesesStr(shortTag);
                }
                newNode.innerHTML = `<a class="ticker-symbol" data-ticker="${shortTag}" data-ticker-id="${tagInfo.ticker}" href="#" data-tag-name="${tagName}" data-tag-id="${topicId}" spellcheck="false" text-decoration="none"><span contenteditable="false"><span contenteditable="false">$${shortTag}</span></span></a>&nbsp;`
            } else {
                newNode.innerHTML = `<a class="ql-hashtag" href="#" id="${topicId}" text-decoration="none" spellcheck="false"><span contenteditable="false">#${tagName}</span></a>&nbsp;`;
            }
            range.insertNode(newNode);
        }
        setTopicId(null);
        setModalOpen(false);
        setIsTicker(false);

        onUpdate(contentField, editorRef.current.getContent(), tagName);
    }

    useEffect(() => {
        setTopics([])
    }, [isTicker])

    return (
        <div className='rich-text-editor'>
            <Editor
                id={contentField}
                value={defaultContent}
                init={{
                    height: defaultHeight,
                    forced_root_block: 'div',
                    image_dimensions: false,
                    content_css: false,
                    content_style: "img { max-width: 100%; height: auto; }",
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar: 'contenttagbtn | stocktagbtn | undo redo | formatselect | fontselect fontsizeselect | bold italic backcolor forecolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist | removeformat',
                    block_formats: 'Paragraph=p;Title 1=h1;Title 2=h2;Title 3=h3',
                    font_formats: 'Arial=arial,helvetica,sans-serif; Source Serif Pro',
                    fontsize_formats: '18px 20px 24px',
                    fontsize_default: '18px',
                    content_style: "body { font-size: 18px; }",
                    file_browser_callback_types: 'image',
                    file_picker_callback: function (cb, value, meta) {
                        let input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = function () {
                            let file = this.files[0];
                            let fileName = `${uuid()}-${file.name}`;
                            let storage = firebaseConfig(BucketType.image);
                            const storageRef = ref(storage, `/post/${encodeURIComponent(fileName.replace(/[ !@#$%^&*(),?":{}|<>]/g, '-'))}`);

                            const tryAgainTask = () => {
                                const uploadTask = uploadBytesResumable(storageRef, file);
                                message.loading('Image uploading...');
                                uploadTask.on(
                                    "state_changed",
                                    (snapshot) => { },
                                    (err) => {
                                        message.destroy();
                                        message.error(String(err));
                                    },
                                    () => {
                                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                            let updated = url;
                                            message.destroy();
                                            cb(updated, { title: fileName });
                                        }).catch((err) => {
                                            message.destroy();
                                            message.error(String(err));
                                        });
                                    }
                                );
                            }
                            const createUploadTask = () => {
                                const uploadTask = uploadBytesResumable(storageRef, file);
                                message.loading('Image uploading...');
                                uploadTask.on(
                                    "state_changed",
                                    (snapshot) => { },
                                    (err) => {
                                        message.destroy();
                                        message.error(String(err));
                                        reAuthenticationForStorage(() => {
                                            tryAgainTask();
                                        })
                                    },
                                    () => {
                                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                            let updated = url;
                                            message.destroy();
                                            cb(updated, { title: fileName });
                                        }).catch((err) => {
                                            message.destroy();
                                            message.error(String(err));
                                        });
                                    }
                                );
                            }
                            createUploadTask();
                        };
                        input.click();
                    },
                    setup: (editor) => {
                        if (!needEditTags) return;
                        // 自定义工具栏按钮
                        editor.ui.registry.addButton('contenttagbtn', {
                            text: 'Insert #',
                            onAction: () => {
                                setIsTicker(false);
                                setModalOpen(true);
                            }
                        });
                        editor.ui.registry.addButton('stocktagbtn', {
                            text: 'Insert Market',
                            onAction: () => {
                                setIsTicker(true);
                                setModalOpen(true);
                            }
                        });
                    }
                }}
                onInit={(evt, editor) => {
                    editorRef.current = editor;
                }}
                onEditorChange={(data, _) => onUpdate(contentField, data)}
            />
            <Modal
                width={720}
                closable
                open={modalOpen}
                centered
                title={isTicker ? 'Insert Market Tag' : 'Insert Content Tag'}
                zIndex={111}
                onCancel={() => setModalOpen(false)}
                footer={[
                    <Button key={'tagbtn'} type='primary' disabled={!topicId} onClick={confirmInsertTag}>Confirm Insert</Button>
                ]}>
                <div style={{ padding: '20px 0' }}>
                    <Select
                        style={{ width: '100%' }}
                        size='large'
                        placeholder={isTicker ? 'Please input keywords to search market tag' : 'Please input keywords to search content tag'}
                        value={topicId}
                        defaultActiveFirstOption={false}
                        suffixIcon={null}
                        filterOption={false}
                        onSearch={(value) => getTopicTags(value)}
                        notFoundContent={searchLoading ? <Spin /> : null}
                        options={topics}
                        optionRender={(option) => {
                            return (
                                <Space>
                                    <div style={{ flex: 1 }}>
                                        <b style={{ wordBreak: 'break-word' }}>{option.data.label}</b>
                                        <div>
                                            <Typography.Text style={{ color: '#999', fontSize: 12 }}>
                                                {option.data.createdAt}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Space>
                            )
                        }}
                        onChange={onSelectChange}
                        allowClear
                        showSearch
                    />
                </div>
            </Modal>
        </div>
    )
}

export default RichTextEditor;