import { Descriptions, Input, Select, Tabs, Button, message, Flex } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { CloudStorageImageUploader } from "../../components/upload";
import { customUploaderBtn } from "../../components/comm/custom_uploader_btn";
import { createCourse, fetchCourseCategories, updateCourse } from "../../api/learning";
import CourseArticleTabView from "../../components/learning/course_article_tab_view";

const LearningCourseDetailScreen = () => {
    const navigateTo = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const defaultData = (location.state && location.state.detail ? JSON.parse(location.state.detail) : {})
    const [data, setData] = useState(defaultData);
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);

    const onFormChange = (key, value) => {
        let updated = { ...data };
        updated[key] = value;
        setData(updated);
    }

    useEffect(() => {
        fetchCourseCategories({ 'page.disable': true }).then(res => {
            if (res.list) {
                setCategories(res.list.map(item => {
                    return {
                        key: item.id,
                        value: item.id,
                        label: item.name,
                    }
                }))
            }
        })
    }, [])

    const onUpdate = (files) => {
        let url = ''
        if (files[0]) {
            url = files[0].remoteUrl ?? files[0].url
        }
        onFormChange('cover', url)
    }

    const coverUploadProps = {
        name: 'cover-uploader',
        bucketPath: '/course/cover',
        defaultFileList: useMemo(() => {
            let coverUrl = data.cover;
            return (coverUrl ? [{
                name: (decodeURIComponent(coverUrl).split('/').pop() ?? '').split('?')[0],
                url: coverUrl,
                percent: 100,
            }] : []);
        }, [data.cover]),
        isMultiple: false,
        listType: 'picture-card',
        needItemRender: false,
        buttonNode: customUploaderBtn('Upload Cover'),
        onUpdate: onUpdate,
    }

    const tabItems = () => {
        if(id === '0') return [
            {
                label: 'Course Info',
                key: 1,
                children: courseInfoView(),
            }
        ]
        return [
            {
                label: 'Course Info',
                key: 1,
                children: courseInfoView(),
            },
            {
                label: 'Course Articles',
                key: 2,
                children: <CourseArticleTabView courseId={id} courseTitle={data.title} />,
                disabled: id === '0' ? true : false
            }
        ]
    }

    const onSubmit = () => {
        setLoading(true);
        let params = {
            categoryId: data.categoryId,
            title: data.title,
            subTitle: data.subTitle,
            cover: data.cover,
            intro: data.intro,
            points: data.points
        };
        if(id === '0') {
            createCourse(params).then(res => {
                if(res) {
                    message.success('Course created!');
                    onCancel();
                }
            }).finally(() => {
                setLoading(false)
            })
        } else {
            updateCourse({...params, id}).then(res => {
                if(res) {
                    message.success('Course updated!');
                    onCancel();
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const onCancel = () => {
        navigateTo(-1);
    }

    const courseInfoView = () => {
        return (
            <div>
                <Descriptions size='large' column={2} labelStyle={{ width: 250, textAlign: 'center' }} bordered>
                    <Descriptions.Item label='Title' span={2}>
                        <Input value={data.title} placeholder="course title" onChange={evt => onFormChange('title', evt.target.value)} allowClear />
                    </Descriptions.Item>
                    <Descriptions.Item label='Sub Title(Option)' span={1}>
                        <Input value={data.subTitle} placeholder="course sub title" onChange={evt => onFormChange('subTitle', evt.target.value)} allowClear />
                    </Descriptions.Item>
                    <Descriptions.Item label='Points(Option)' span={1}>
                        <Input value={data.points} placeholder="points" onChange={evt => onFormChange('points', evt.target.value)} type="number" />
                    </Descriptions.Item>
                    <Descriptions.Item label='Category' span={2}>
                        <Select
                            value={data.categoryId}
                            style={{ width: '100%' }}
                            placeholder="course category"
                            onChange={value => onFormChange('categoryId', value)}
                            options={categories}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label='Cover' span={2}>
                        <CloudStorageImageUploader {...coverUploadProps} />
                    </Descriptions.Item>
                    <Descriptions.Item label='Intro' span={2}>
                        <Input.TextArea value={data.intro} placeholder="course introduction...." rows={6} onChange={evt => onFormChange('intro', evt.target.value)} allowClear />
                    </Descriptions.Item>
                </Descriptions>

                <Flex justify="center" style={{marginTop: 24}}>
                    <Button loading={loading} type="primary" style={{ width: 200, height: 44 }} onClick={onSubmit}>Submit</Button>
                    <div style={{ width: 24 }} />
                    <Button loading={loading} block style={{ width: 200, height: 44 }} onClick={onCancel}>Cancel</Button>
                </Flex>
            </div>
        )
    }

    return (
        <div style={{ padding: '20px' }}>
            <div className="title">{id ? (id === '0' ? 'Create New Course' : 'Edit Course') : ''}</div>
            <div style={{ height: 12 }} />
            <Tabs items={tabItems()} />
        </div>
    )
}

export default LearningCourseDetailScreen;