import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import cookie from '../utils/cookie';
import request from '../utils/request';
import { getAppCheckV3Key } from '../utils/base_url';

export const firebaseAuthApp = () => {
  let app = initializeApp(authConfig(), 'auth');
  if (process.env.REACT_APP_MODE === 'test' || process.env.REACT_APP_MODE === 'prod') {
    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(getAppCheckV3Key()),
      isTokenAutoRefreshEnabled: true,
    })
  }
  return app;
}

export const authConfig = () => {
  let firebaseAuthConfig = {
    apiKey: "AIzaSyCLH6AYhX32QO_mO8TXQ5tsrIOWq1fYUuM",
    authDomain: "go-wallet-dev-396512.firebaseapp.com",
    projectId: "go-wallet-dev-396512",
    storageBucket: "go-wallet-dev-396512.appspot.com",
    messagingSenderId: "254703063534",
    appId: "1:254703063534:web:38fa458945dfa6aaba3eff",
    measurementId: "G-02CF9GXMBL"
  };

  if (process.env.REACT_APP_MODE === 'test') {
    firebaseAuthConfig = {
      apiKey: "AIzaSyAP4Mq1UVjp7UJQU9s8fFBouOz1ZWlTaAM",
      authDomain: "go-wallet-test-396515.firebaseapp.com",
      projectId: "go-wallet-test-396515",
      storageBucket: "go-wallet-test-396515.appspot.com",
      messagingSenderId: "979486348420",
      appId: "1:979486348420:web:a6a56816ca2565d8ba1ed1",
      measurementId: "G-V52SM5381W"
    };
  } else if (process.env.REACT_APP_MODE === 'prod') {
    firebaseAuthConfig = {
      apiKey: "AIzaSyBtURlA2pCuMoi-fHsaGPBjHUhXLAo3nxM",
      authDomain: "go-wallet-production-396515.firebaseapp.com",
      projectId: "go-wallet-production-396515",
      storageBucket: "go-wallet-production-396515.appspot.com",
      messagingSenderId: "562260331334",
      appId: "1:562260331334:web:d179a0dd85b5bcba3c7347",
      measurementId: "G-EGJCV4TJL5"
    };
  }
  return firebaseAuthConfig;
}

export const refreshJWTToken = (onSuccess, onFail) => {
  let url = `https://securetoken.googleapis.com/v1/token?key=${authConfig().apiKey}`;
  let params = {
    grant_type: 'refresh_token',
    refresh_token: window.localStorage.getItem('rjwt')
  };
  request.post(url, params).then(result => {
    if (result.access_token && result.refresh_token) {
      cookie.setCookie('user-token', result.access_token);
      window.localStorage.setItem('rjwt', result.refresh_token);
      onSuccess();
    } else {
      onFail(JSON.stringify(result));
    }
  }).catch(err => {
    onFail(err);
  });
}