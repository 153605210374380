import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, Image, Tag, Popconfirm, message, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { fetchBannerList, createBanner, updateBanner, deleteBanner } from '../../api';
import { MainLayoutUrl } from '../../router/router_path';
import { BannerOptions } from './detail';

const BannerScreen = (props) => {
    const navigate = useNavigate();
    const tabCols = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Display Area',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => {
                let item = BannerOptions.find(ele => ele.value === text);
                if (item) return <Tag>{item.label}</Tag>;
                return '';
            }
        },
        {
            title: 'Preview',
            dataIndex: 'preview',
            key: 'preview',
            render: (text, { list }) => {
                if (!list || list.length <= 0) return <div>-</div>;
                return (
                    <Space wrap>
                        {
                            list.map(item => {
                                return <Image key={item.id} src={item.image} style={{ maxWidth: 100, maxHeight: 50, margin: '2px auto' }} />
                            })
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Desc',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (String(text) === '0') return <Tag color='success'>Active</Tag>;
                return <Tag color='warning'>Inactive</Tag>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Button type='link' onClick={() => { activeHandle(record) }}>{String(record.status) === '0' ? 'Inactive' : 'Active'}</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this banner?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize);
    }, []);

    const fetchData = (pNum, pSize) => {
        setLoading(true);
        let params = { 'page.num': pNum, 'page.size': pSize };
        fetchBannerList(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const createNewBanner = () => {
        createBanner({ title: '', description: '' }).then(res => {
            if (res) {
                navigate(MainLayoutUrl.banner.item.path.replace(':id', res.id), { state: { detail: JSON.stringify({ ...res }) } })
            }
        });
    }

    const editHandle = (item) => {
        navigate(MainLayoutUrl.banner.item.path.replace(':id', item.id), { state: { detail: JSON.stringify(item) } })
    }

    const activeHandle = (item) => {
        let params = { ...item, status: String(item.status) === '0' ? 1 : 0, };
        updateBanner(params).then(res => {
            fetchData(pageNum, pageSize);
        });
    }

    const deleteHandle = (item) => {
        setLoading(true);
        deleteBanner(item.id).then(res => {
            if(res) {
                message.success('deleted!');
                setLoading(false);
                fetchData(pageNum, pageSize);
            }
        }).catch((err) => {
            message.error(String(err));
            setLoading(false);
        })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={createNewBanner}>Create New Banner</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default BannerScreen;