import cookie from '../utils/cookie';
import { message } from 'antd';
import axios from 'axios';
import { getBaseApi, getCryptoJSKey } from './base_url';
import { firebaseAuthApp, refreshJWTToken } from '../firebase/auth';
import { getAuth } from 'firebase/auth'

const BASE_URL = `https://${getBaseApi()}/api/admin`;

function post(url, params, appCheckToken = null) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
        }
        if (url.includes('googleapis')) {
            headers['Content-Type'] = 'application/json';
        } else {
            let token = cookie.getCookie('user-token');
            if (token) {
                headers['Authorization'] = 'Bearer ' + token;
            }
        }

        if (appCheckToken) {
            headers['X-Firebase-Appcheck'] = appCheckToken;
        }

        let fullUrl = url.includes('googleapis') ? url : (BASE_URL + url);
        axios.post(fullUrl, params, { headers, timeout: 60000 }).then(response => {
            resolve(response.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication(async () => {
                    try {
                        let jwt = cookie.getCookie('user-token');
                        headers['Authorization'] = 'Bearer ' + jwt;
                        let result = await axios.post(fullUrl, params, { headers, timeout: 60000 })
                        resolve(result.data);
                    } catch (error) {
                        if (error.response.data && error.response.data.message) {
                            message.error(error.response.data.message, 8)
                        }
                        resolve(false);
                    }
                });
            } else if (url.includes('googleapis')) {
                resolve(false);
            } else {
                if (err.response.data && err.response.data.message) {
                    message.error(err.response.data.message, 8)
                }
                resolve(false);
            }
        });
    })
}

function get(url, params) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        let config = { params, headers, timeout: 60000 };
        if (url.includes('download')) {
            config.responseType = 'arraybuffer';
        }
        axios.get(BASE_URL + url, config).then(response => {
            resolve(response.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication(async () => {
                    try {
                        let jwt = cookie.getCookie('user-token');
                        headers['Authorization'] = 'Bearer ' + jwt;
                        let result = await axios.get(BASE_URL + url, { params, headers }, { timeout: 60000 })
                        resolve(result.data);
                    } catch (error) {
                        if (error.response.data && error.response.data.message) {
                            message.error(error.response.data.message, 8)
                        }
                        resolve(false);
                    }
                });
            } else {
                reject(err);
            }
        });
    })
}

function patch(url, params) {
    return new Promise((resolve, reject) => {
        let headers = {
            'Content-Type': 'application/json',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.patch(BASE_URL + url, params, { headers: headers }, { timeout: 60000 }).then(response => {
            resolve(response.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication(async () => {
                    try {
                        let jwt = cookie.getCookie('user-token');
                        headers['Authorization'] = 'Bearer ' + jwt;
                        let result = await axios.patch(BASE_URL + url, params, { headers: headers }, { timeout: 60000 })
                        resolve(result.data);
                    } catch (error) {
                        if (error.response.data && error.response.data.message) {
                            message.error(error.response.data.message, 8)
                        }
                        resolve(false);
                    }
                });
            } else {
                if (err.response.data && err.response.data.message) {
                    message.error(err.response.data.message, 8)
                }
                resolve(false);
            }
        });
    })
}

function dataDel(url) {
    return new Promise(resolve => {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let token = cookie.getCookie('user-token');
        if (token) {
            headers['Authorization'] = 'Bearer ' + token;
        }
        axios.delete(BASE_URL + url, { params: {}, headers: headers, timeout: 60000 }).then(response => {
            resolve(response.data);
        }).catch((err) => {
            if (err.response.status === 401) {
                reAuthentication(async () => {
                    try {
                        let jwt = cookie.getCookie('user-token');
                        headers['Authorization'] = 'Bearer ' + jwt;
                        let result = await axios.delete(BASE_URL + url, { params: {}, headers: headers, timeout: 60000 })
                        resolve(result.data);
                    } catch (error) {
                        if (error.response.data && error.response.data.message) {
                            message.error(error.response.data.message, 8)
                        }
                        resolve(false);
                    }
                });
            } else {
                if (err.response.data && err.response.data.message) {
                    message.error(err.response.data.message, 8)
                }
                resolve(false);
            }
        })
    })
}

export function reAuthentication(callback) {
    const auth = getAuth(firebaseAuthApp());
    console.log('auth', auth);
    console.log('auth name', auth.name);
    if (auth) {
        auth.authStateReady().then(() => {
            const user = auth.currentUser;
            if (user) {
                user.getIdToken(true).then(idToken => {
                    cookie.setCookie('user-token', idToken);
                    callback();
                }).catch(err => {
                    console.log(`relogin error = ${err}`);
                    cookie.deleteCookie('user-token');
                    window.location.reload();
                })
            } else {
                console.log(`relogin error = no user`);
                cookie.deleteCookie('user-token');
                window.location.reload();
            }
        })
    } else {
        console.log(`relogin error = no auth`);
        cookie.deleteCookie('user-token');
        window.location.reload();
    }
}

export function reAuthenticationForStorage(callback) {
    const auth = getAuth(firebaseAuthApp());
    if (auth) {
        auth.authStateReady().then(() => {
            const user = auth.currentUser;
            if (user) {
                user.getIdToken(true).then(idToken => {
                    cookie.setCookie('user-token', idToken);
                    callback();
                })
            }
        })
    }
}

export default {
    post,
    get,
    patch,
    dataDel
}
