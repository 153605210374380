import { Drawer, Space, Button, Tag, Image, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useState, useRef } from "react";
import { fetchCommentListStack, fetchMoreChildComment } from "../../api/community";
import { capitalizeFirstLetter, iframeToVideoForH5 } from "../../utils/comm";
import TopicIcon from '../../assets/topic.svg';
import { useDebouncedCallback } from "use-debounce";
import '../../style/community.css';
import UserInfoView from "../community/user_info_view";
import PostCommentsView from "../community/post_comment_view";
import VirtualCommentForPostModalView from "../community/virtual_comment_for_post_modal_view";

const CourseArticlePreview = ({ post, open, onClose, from = 'course_article' }) => {
    const [loadMore, setLoadMore] = useState(true);
    const [commentPageNum, setCommentPageNum] = useState(1);
    const [comments, setComments] = useState([]);
    const [commentsTotal, setCommentsTotal] = useState(0);
    const [currComment, setCurrComment] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [commentPageSize, setCommentPageSize] = useState(10);
    const [commentLoading, setCommentLoading] = useState(false);

    const tooltipRef = useRef(null);
    useEffect(() => {
        setComments([]);
        setCommentPageNum(1);
        setCommentsTotal(0);
        setCurrComment(undefined);
        setCommentLoading(false);
        setLoadMore(true);
        if (post) {
            getComments(false, 1, commentPageSize);
            let ele = document.getElementById(`${from}_pcnt_${post.id}`);
            if (ele) {
                ele.innerHTML = '';
                let content = document.createElement('div');
                content.innerHTML = iframeToVideoForH5(post.content);
                let scripts = content.querySelectorAll('script');
                scripts.forEach((ele) => ele.remove());
                ele.appendChild(content);
            }

            let scroll = document.getElementById(`scroll_${post.id}`);
            scroll.onscroll = () => {
                let diff = scroll.scrollHeight - (scroll.clientHeight + parseInt(scroll.scrollTop));
                if (diff <= 1) {
                    getComments(true);
                }
            }
        }
    }, [post])

    const getComments = useDebouncedCallback(
        (more, num, pSize) => {
            if (commentLoading) return;
            let pNum = num ? num : (more ? (commentPageNum + 1) : commentPageNum);
            if (!loadMore && pNum > 1) return;
            setCommentLoading(true);
            let params = {
                'page.num': pNum,
                'page.size': pSize ? pSize : commentPageSize,
                'postId': post.id
            }
            fetchCommentListStack(params).then(res => {
                if (res.list) {
                    setCommentsTotal(res.commentTotal);
                    if (pNum == 1) {
                        setComments(res.list);
                    } else {
                        setComments([...comments, ...res.list]);
                    }
                    if (Array.from(res.list).length < commentPageSize) {
                        setLoadMore(false);
                        setCommentPageNum(pNum > 1 ? pNum - 1 : 1);
                    } else {
                        setLoadMore(true);
                        setCommentPageNum(pNum);
                    }
                }
            }).finally(() => {
                setCommentLoading(false);
            })
        }, 300
    )

    const buildArticleView = () => {
        return (
            <>
                <h3>{post.title}</h3>
            </>
        )
    }

    const buildCourseMedia = () => {
        let { resources, audioResources } = post;
        if (post.courseMediaType === '1' && resources[0]) {
            return (
                <div style={{ margin: '16px 0' }}>
                    <video controls src={open ? (resources[0].url ? resources[0].url : resources[0]) : ''} style={{ width: '100%', height: 'auto' }} />
                </div>
            )
        } else if(post.courseMediaType === '2' && audioResources[0]) {
            return (
                <div style={{ margin: '16px 0' }}>
                    <audio controls src={open ? (audioResources[0].url ? audioResources[0].url : audioResources[0]) : ''} style={{ width: '100%' }} />
                </div>
            )
        }
        return ''
    }

    const buildStatus = () => {
        let status = post.status;
        if (String(status) === '3') {
            return (
                <Tooltip ref={tooltipRef} title={post.riskReason} color='orange'>
                    <Tag color="red">RISK</Tag>
                </Tooltip>
            )
        } else if (String(status) === '2' || String(status) === '5' || String(status) === '6') {
            return <Tag style={{ fontSize: 12 }} color="processing">PENDING</Tag>;
        } else if (String(status) === '1') {
            return <Tag style={{ fontSize: 12 }}>PUBLISHED</Tag>;
        }
        return ''
    }

    const buildVoteView = () => {
        if (!post.vote || parseInt(post.vote.id) <= 0 || Array.from(post.vote.options ?? []).length <= 0) {
            return <div />
        }
        const totalCount = Array.from(post.vote.options).map(item => parseInt(item.voteCount)).reduce((x, y) => (x + y));
        return (
            <div style={{ marginTop: 24 }}>
                <h5>[Vote] {post.vote.description}</h5>
                {
                    post.vote.options.map(item => {
                        const voteCount = parseInt(item.voteCount);
                        const percent = 100 * voteCount / totalCount;
                        return (
                            <div key={item.id} className="vote-item">
                                <div className="vote-bar" style={{ width: `${totalCount > 0 ? (percent + '%') : 0}` }} />
                                <div className="vote-cnt">
                                    <div className="t1">{capitalizeFirstLetter(item.content)}</div>
                                    <div className="t2">{item.voteCount} vote</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    const buildTags = () => {
        let tags = Array.from(post.tags ?? []).filter((item) => item.isTopic === true || item.tag?.isTopic === true).map((item) => {
            if (item.tag) {
                return item.tag.name
            }
            return item.name
        });
        if (tags.length <= 0) return null;
        return (
            <Space wrap style={{ margin: '8px 0' }}>
                {tags.map((item, index) => {
                    return <div className="post-topic-item" key={index}>
                        <Space>
                            <Image alt="tag" src={TopicIcon} style={{ pointerEvents: 'none' }} />
                            {item}
                        </Space>
                    </div>
                })}
            </Space>
        )
    }

    const replyHandle = (comment) => {
        setCurrComment(comment);
        setModalShow(true);
    }

    const onCommentUpdate = () => {
        setCurrComment(null);
        let pSize = 10 * commentPageNum;
        setCommentPageSize(pSize);
        setCommentPageNum(1);
        setCommentLoading(false);
        getComments(false, 1, pSize);

        if (onUpdate) {
            onUpdate();
        }
    }

    const viewMoreHandle = (commentId) => {
        let params = {
            'commentId': commentId,
            'page.disable': true
        }
        fetchMoreChildComment(params).then(res => {
            if (res.list && res.list.length > 0) {
                let newData = Array.from(JSON.parse(JSON.stringify(comments)));
                let tmpComment = newData.find(item => item.id === commentId);
                if (tmpComment) {
                    tmpComment.children = res.list;
                    tmpComment.childCount = res.list.length;
                }
                setComments(newData);
            }
        })
    }

    if (!post) return <div />;
    let user = post.user;

    return (
        <div>
            <Drawer
                key={post.id}
                forceRender={true}
                title={'Course Article Preview'}
                width={480}
                maskClosable
                onClose={onClose}
                open={open}
                extra={<Button onClick={onClose}>Close</Button>} >
                <div id={`scroll_${post.id}`} style={{ height: '100%', overflow: 'auto' }}>
                    <Space style={{ width: '100%' }}>
                        <UserInfoView user={user} showWallet={false} />
                        {buildStatus()}
                    </Space>
                    <div style={{ color: '#ccc', fontSize: 12, marginTop: 12 }}>{dayjs(post.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                    <div style={{ margin: '16px 0' }}>
                        {buildArticleView()}
                    </div>
                    {buildCourseMedia()}
                    <div className="article-content-review" id={`${from}_pcnt_${post.id}`} />

                    {buildTags()}
                    {buildVoteView()}

                    <PostCommentsView
                        loading={commentLoading}
                        comments={comments}
                        total={commentsTotal}
                        replyCallback={replyHandle}
                        viewMoreCallback={viewMoreHandle} />
                </div>
            </Drawer>

            <VirtualCommentForPostModalView
                post={post}
                replyComment={currComment}
                open={modalShow}
                onClose={() => setModalShow(false)}
                onUpdate={onCommentUpdate} />
        </div>
    )
}

export default CourseArticlePreview;