import { DeleteOutlined, EditOutlined, EyeFilled, RocketOutlined, XOutlined } from "@ant-design/icons";
import { DatePicker, Pagination, Table, Input, Space, Button, Flex, Tooltip, Popconfirm, Tag, Typography, Image } from "antd";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import { useDebouncedCallback } from "use-debounce";
import { deleteCrawledPost, fetchCrawledList } from "../../api/community";
import { RichText } from "@chatui/core";
import CrawledReview from "../../components/community/crawled_review";
import { MainLayoutUrl } from "../../router/router_path";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const CrawledPostsScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1)
        },
        {
            title: 'Original Published Date',
            dataIndex: 'publishedAt',
            key: 'publishedAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf', whiteSpace: 'nowrap' }}>{dayjs(text).format('MM-DD HH:mm')}</label>
            },
        },
        {
            title: 'Original Source',
            dataIndex: 'platform',
            key: 'platform',
            width: 300,
            render: (text, record) => {
                if (text === 0) {
                    return (
                        <Link target="_blank" to={record.link}>
                        <Flex>
                            <XOutlined style={{ fontSize: 12 }} />
                            <div style={{ width: 150, whiteSpace: 'nowrap', marginLeft: 4 }}>
                                <Typography.Text style={{ fontSize: 11, whiteSpace: 'nowrap' }} copyable ellipsis>{record.link}</Typography.Text>
                            </div>
                        </Flex>
                        </Link>
                    )
                }
                return ''
            },
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            render: (text, _) => {
                return <b>{text}</b>
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, _) => {
                if (text === 1) {
                    return <Tag color="green">Published</Tag>
                }
                return <Tag color="volcano">Unedited</Tag>
            },
        },
        {
            title: 'Images',
            dataIndex: 'images',
            key: 'images',
            render: (text, _) => {
                return (
                    <Space wrap>
                        {
                            text.split(',').map((item, index) => {
                                return <Image key={index} src={item} style={{ maxWidth: 120, height: 'auto' }} />
                            })
                        }
                    </Space>
                )
            },
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (_, record) => {
                return (
                    <div style={{ background: '#f1f1f1', padding: '8px 12px', borderRadius: 8, width: 320 }}>
                        <div className="crawled-content">
                            <RichText content={record.title ? record.title : record.content} />
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            render: (text, record, index) => {
                return (
                    <Space size='small'>
                        <Tooltip title='Review Post' key={`review_${index}`}>
                            <Button shape="circle" onClick={() => { reviewHandle(record) }} icon={<EyeFilled />} />
                        </Tooltip>
                        {
                            record.status === 0 && (
                                <>
                                    <Tooltip title='Publish' key={`publish_${index}`}>
                                        <Button shape="circle" onClick={() => { publishHandle(record) }} icon={<RocketOutlined />} />
                                    </Tooltip>
                                    <Tooltip title='Edit' key={`edit_${index}`}>
                                        <Button shape="circle" onClick={() => { editHandle(record) }} icon={<EditOutlined />} />
                                    </Tooltip>
                                    <Popconfirm
                                        title="Delete Action"
                                        description="Are you sure to delete this post?"
                                        onConfirm={() => { deleteHandle(record) }}
                                        okText="Yes"
                                        cancelText="No" >
                                        <Button danger shape="circle" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </>
                            )
                        }
                    </Space>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        keyword: '',
        status: '',
        gteCreatedAt: '',
        ltCreatedAt: '',
    });
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [currPost, setCurrPost] = useState();

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);
    }, [location])

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchCrawledList(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.gteCreatedAt = `${value[0]}T00:00:00Z`;
                    newParams.ltCreatedAt = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.gteCreatedAt = '';
                    newParams.ltCreatedAt = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const reviewHandle = (record) => {
        setCurrPost(record);
        setOpen(true);
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteCrawledPost(record.id).then(res => {
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => message.error(String(err))).finally(() => {
            setLoading(false);
        });
    }

    const editHandle = (record) => {
        navigate(MainLayoutUrl.community.crawled.item.path.replace(':id', record.id), { state: { detail: JSON.stringify(record) } })
    }

    const publishHandle = (record) => {
        navigate(`${MainLayoutUrl.community.adminpost.item.path.replace(':id', '0')}?mode=publish`, { state: { detail: JSON.stringify({ content: record.content, categoryId: '1', resource: record.images, crawled: record }) } })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify="space-between">
                    <div />

                    <Space wrap style={{ justifyContent: 'flex-end' }}>
                        <DatePicker.RangePicker onChange={values => onSearchChange('dates', (values ?? []).map(date => dayjs(date).format('YYYY-MM-DD')))} />
                        <Input
                            placeholder="keywords search"
                            allowClear
                            onChange={evt => onSearchChange('keyword', evt.target.value)}
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)} />
            </div>

            <CrawledReview
                open={open}
                post={currPost}
                onClose={() => setOpen(false)} />
        </div>
    )
}

export default CrawledPostsScreen;