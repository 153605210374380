import { Button, Space, Table, Pagination, Flex, Select, Popconfirm, message, Tag } from "antd";
import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { PlusOutlined } from "@ant-design/icons";
import { deleteAIQuestion, fetchAICategories, fetchAIQuestions } from "../../api/ai";
import AIQuestionEditModalView from "../../components/ai/questions_edit_modal_view";

const AIQuestionsScreen = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currQuestion, setCurrQuestion] = useState(null);
    const [categories, setCategories] = useState([]);
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1)
        },
        {
            title: 'Question',
            dataIndex: 'content',
            key: 'content',
            render: (text, record) => {
                return (
                    <b>{text}</b>
                )
            },
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (_, record) => {
                const { category } = record;
                if (!category) return;
                return <Tag>{category?.name}</Tag>
            },
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            key: 'weight',
            render: (text, _) => {
                return <b>{text}</b>
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size="small">
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this question?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            },
        }
    ];

    const [searchParams, setSearchParams] = useState({
        categoryId: null
    });

    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize, searchParams);

        fetchAICategories({}).then(res => {
            if (res.list) {
                setCategories(res.list.map(item => {
                    return {
                        key: item.id,
                        value: item.id,
                        label: item.name,
                    }
                }))
            }
        })
    }, []);

    const fetchData = (pNum, pSize, search) => {
        setLoading(true);
        let params = {
            'page.num': pNum,
            'page.size': pSize,
            ...search,
        };
        fetchAIQuestions(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size, searchParams);
    }

    const onSearchChange = useDebouncedCallback(
        (tag, value) => {
            let newParams = { ...searchParams };
            if (tag === 'dates') {
                if (value.length == 2) {
                    newParams.start = `${value[0]}T00:00:00Z`;
                    newParams.end = `${value[1]}T23:59:59Z`;
                } else {
                    newParams.start = '';
                    newParams.end = '';
                }
            } else {
                newParams[tag] = value;
            }
            setSearchParams(newParams);
            setPageNum(1);
            fetchData(1, pageSize, newParams);
        },
        300
    )

    const createNewQuestion = () => {
        setCurrQuestion(null);
        setModalOpen(true);
    }

    const deleteHandle = (item) => {
        setLoading(true);
        deleteAIQuestion(item.id).then(res => {
            setLoading(false);
            fetchData(pageNum, pageSize, searchParams);
        }).catch(err => {
            setLoading(false);
            message.error(String(err));
        })
    }

    const editHandle = (item) => {
        setCurrQuestion(item);
        setModalOpen(true);
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Flex justify='space-between'>
                    <Button type='primary' icon={<PlusOutlined />} onClick={createNewQuestion}>Create Question</Button>
                    <Space>
                        <Select
                            style={{ width: 200 }}
                            options={categories}
                            placeholder='select category...'
                            onChange={value => onSearchChange('categoryId', value)}
                            allowClear
                        />
                    </Space>
                </Flex>
            </div>
            <div className='cnt-item'>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => record.id}
                    rowClassName={'table-row'}
                    size='small'
                    dataSource={tabData}
                    columns={tabCols}
                    pagination={false}
                    expandable={{ rowExpandable: (record) => false, showExpandColumn: false }} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <AIQuestionEditModalView
                open={modalOpen}
                categories={categories}
                defaultQuestion={currQuestion}
                onClose={() => setModalOpen(false)}
                onUpdate={() => {
                    fetchData(pageNum, pageSize, searchParams);
                }} />
        </div>
    )
}

export default AIQuestionsScreen;