import React, { useEffect, useState } from 'react';
import { Table, Button, Pagination, Image, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { MainLayoutUrl } from '../../router/router_path';
import { fetchDailyKnowledgeList } from '../../api';

const DailyScreen = (props) => {
    const navigate = useNavigate();
    const tabCols = [
        {
            title: 'Index',
            dataIndex: 'id',
            key: 'id',
            render: (text, _, index) => ((pageNum - 1) * pageSize + index + 1),
        },
        {
            title: 'Wiki ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, _) => {
                return <Typography.Text copyable>{text}</Typography.Text>
            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 150,
        },
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            key: 'keyword',
            width: 150,
            render: (text, _) => {
                return <b>{text}</b>
            }
        },
        {
            title: 'Content',
            dataIndex: 'content',
            key: 'content',
            render: (text, _) => {
                return  (
                    <div className='daily-content'>
                        {text}
                    </div>
                )
            }
        },
        {
            title: 'Preview',
            dataIndex: 'previewImage',
            key: 'previewImage',
            width: 150,
            render: (text, _) => {
                if(!text) return <div />;
                if(!String(text).includes('https://')){
                    text = `https://${text}`;
                }
                return (
                    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <Image src={text} style={{maxWidth: 120, maxHeight: 60}} />
                    </div>
                )
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { editDaily(record) }}>Edit</Button>
                    </Button.Group>
                )
            }
        }
    ];
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchData(pageNum, pageSize);
    }, []);

    const fetchData = (pNum, pSize) => {
        setLoading(true);
        let params = { 'page.num': pNum, 'page.size': pSize };
        fetchDailyKnowledgeList(params).then(res => {
            if (res.page) {
                setTotal(res.page.total);
            }
            if (res.list) {
                setTabData(res.list);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        fetchData(page, size);
    }

    const createNewDaily = () => {
        navigate(MainLayoutUrl.daily.knowledge.item.path.replace(':id', '0'));
    }

    const editDaily = (item) => {
        navigate(MainLayoutUrl.daily.knowledge.item.path.replace(':id', item.id), { state: { detail: JSON.stringify(item) } })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={createNewDaily}>Create New Daily Knowledge</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>
        </div>
    )
}

export default DailyScreen;