import { useEffect, useState } from 'react'
import { Button, Popconfirm, Table, Pagination, Typography, Tooltip, Tag, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { message } from 'antd';
import UserInfoView from '../../components/community/user_info_view';
import { useNavigate } from 'react-router-dom';
import { MainLayoutUrl } from '../../router/router_path';
import { deleteCouseArticle, fetchCourseArticles } from '../../api/learning';
import CourseArticlePreview from '../../components/learning/course_article_preview';

const LearningArticlesScreen = () => {
    const navigateTo = useNavigate();
    const [tabData, setTabData] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currArticle, setCurrArticle] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);

    const tabCols = [
        {
            title: 'Post ID',
            dataIndex: 'postId',
            key: 'postId',
            render: (text, _) => {
                return <Typography.Text copyable style={{ fontSize: 12, color: '#afafaf' }}>{text}</Typography.Text>
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 200,
            render: (_, record) => {
                const { post } = record
                return <b>{post.title}</b>;
            },
        },
        {
            title: 'User Info',
            dataIndex: 'user',
            key: 'user',
            render: (_, record) => {
                const { post } = record
                let { user } = post;
                return (
                    <UserInfoView user={user} />
                );
            }
        },
        {
            title: 'Course',
            dataIndex: 'course',
            key: 'course',
            width: 200,
            render: (_, record) => {
                const {course} = record;
                if(course.id === '0') return '';
                return `《${course.title}》`
            }
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, _) => {
                return <label style={{ fontSize: 12, color: '#afafaf' }}>{dayjs(text).format('YYYY-MM-DD HH:mm')}</label>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (_, record) => {
                const { post } = record;
                let text = post.status;
                if (String(text) === '3') {
                    return (
                        <Tooltip title={post.riskReason} color='orange'>
                            <Tag color="red">RISK</Tag>
                        </Tooltip>
                    )
                } else if (String(text) === '2' || String(text) === '5' || String(text) === '6') {
                    return <Tag style={{ fontSize: 12 }} color="processing">PENDING</Tag>;
                } else if (String(text) === '1') {
                    return <Tag style={{ fontSize: 12 }}>PUBLISHED</Tag>;
                }
                return ''
            },
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            render: (text, record, index) => {
                return (
                    <Button.Group size='small'>
                        <Button type='link' onClick={() => { reviewHandle(record) }}>Review</Button>
                        <Button type='link' onClick={() => { editHandle(record) }}>Edit</Button>
                        <Popconfirm
                            title="Delete Action"
                            description="Are you sure to delete this course article?"
                            onConfirm={() => { deleteHandle(record) }}
                            okText="Yes"
                            cancelText="No" >
                            <Button danger type='link'>Delete</Button>
                        </Popconfirm>
                    </Button.Group>
                )
            }
        }
    ];

    useEffect(() => {
        getCourseArticles(pageNum, pageSize);
    }, [])

    const getCourseArticles = (pNum, pSize) => {
        setLoading(true);

        let params = {
            'page.num': pNum,
            'page.size': pSize,
            sort: 0
        }
        fetchCourseArticles(params).then(res => {
            if (res.list) {
                setTabData(res.list);
            }
            if (res.page) {
                setTotal(res.page.total);
            }
        }).finally(() => setLoading(false))
    }

    const pageChange = (page, size) => {
        setPageNum(page);
        setPageSize(size);
        getCourseArticles(page, size);
    }

    const reviewHandle = (record) => {
        setCurrArticle(record);
        setPreviewOpen(true);
    }

    const editHandle = (record) => {
        let { post } = record;
        navigateTo(MainLayoutUrl.learning.learningarticles.item.path.replace(':id', post.id), { state: { detail: JSON.stringify(post) } })
    }

    const addCourseArticle = () => {
        navigateTo(MainLayoutUrl.learning.learningarticles.item.path.replace(':id', 0));
    }

    const deleteHandle = (record) => {
        setLoading(true);
        deleteCouseArticle(record.id).then(res => {
            if (res) {
                message.success('deleted!');
                getCourseArticles(pageNum, pageSize);
            }
        }).catch(err => {
            message.error(String(err));
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <div className='main-wrapper'>
            <div className='top-part cnt-item'>
                <Button type='primary' icon={<PlusOutlined />} onClick={addCourseArticle}>Create Course Article</Button>
            </div>
            <div className='cnt-item'>
                <Table loading={loading} rowKey={(record) => record.id} rowClassName={'table-row'} size='small' dataSource={tabData} columns={tabCols} pagination={false} />
            </div>
            <div className='footer-part cnt-item'>
                <Pagination
                    total={total}
                    showTotal={(total) => `total ${total}`}
                    current={pageNum}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    pageSizeOptions={[10, 20, 30, 50]}
                    onChange={(page, size) => pageChange(page, size)}
                />
            </div>

            <CourseArticlePreview post={currArticle?.post} open={previewOpen} onClose={() => setPreviewOpen(false)} />
        </div>
    )
}

export default LearningArticlesScreen;